export default {
    index: {
        startMining: '採掘開始',
        Introductionofmining: 'マイニング入門',
        defiremark: 'DeFiの流動性マイニングは、ETH2.0のメインネットワークDeFiノードのチェーンマイニング方法の新世代だけでなく、ビットコインの後に新しいマイニング方法に基づいており、主にゼロストレージコンセンサスメカニズムのブロックチェーンホルダー、DeFiのために、つまり、分散型金融、集中化の固有の欠点を排除することを目的とし、ユーザーのしきい値を簡素化し、ノードを介して各ユーザーは、26流動性のチェーンのビジネスモデルになることができます。 貢献者は、ユーザーが唯一のコミュニティに参加する必要が仮想鉱夫になることができ、コミュニティの料金に参加する最初の時間は、各流動性貢献者が利益を得ることができるように、操作のための集団として、単一のノード、ゼロチェーンのデジタルでドッキング鉱夫マイニングインターフェイスチェーンをアクティブにすることができます。 資産は、リスクなしで、より自由で柔軟な引き出しで自分の財布に格納されています。 個人用ウォレットとトランザクション・サービスのための分散型プロトコルを通じて、機関ではなく個人ユーザーに明確に対応するDeFiエコシステムを奨励することで、流動性貢献者に毎年数百万ドルの収益をもたらす可能性がある。',
        Advantages: 'ゆうせい',
        Safe: 'セーフ',
        notransfers: '振り込みは不要で、資金はあなたの財布にある！',
        Professional: 'エキスパート',
        BasedOn: 'DeFiプロジェクトのブロックチェーン技術とBinanceチームの安全な運営に基づく。',
        Lowthreshold: '敷居が低い',
        nullstorage:'100usdtデポジット後の共有ノードマイニング収益',
        CommonProblems:'よくある質問への回答',
        Auditinstitutions:'監査権限'
    },
    pool: {
        Totalliquiditynode:'総流動性',
        Participation:'さんする',
        Currenthashrate:'カレントハッシュ',
        Totalrevenue:'総収入',
        Levelincome:'平均所得',
        Level:'位',
        Earningratio:'利回り',
        Incomerecords:'収入記録',
        Time:'回',
        Income:'収入',
        Notice:'通知'
    },
    machine:{
        Machine:'機械類',
        Pledge:'誓約',
        My:'私の',
        Fastliquiditypledges:'迅速な流動性誓約',
        Apply:'適用',
        Price:'物価',
        DailyRate:'日割',
        Cycle:'周期',
        Day:'日'
    },
    mime:{
        Invitefriends:'友達を招待して一緒にお金を稼ぐ',
        Copylink:'招待状リンクをコピーしてください。',
        Balance:'収支',
        Pledge:'誓約',
        Join:'ステーク',
        Withdraw:'引き出し',
        Transferpledge:'移籍の誓約',
        Transferbalance:'ステーク解除',
        Address:'住所',
        TodayEarnings:"本日の決算",
        Introductionbenefits:'導入のメリット',
        VIPINFO:'VIP情報',
        Level:'位',
        Recommended:'提案',
        Brokerage:'ブローカー',
        sendyour:'招待リンクを送信すると、お友達があなたのリンクを通じてノードに参加し、VIPレベルに応じて豪華なトークンが与えられます。 報酬のパーセンテージは、お友達の収益のパーセンテージに基づいて分配されます。',
        Receivebenefits:'ベネフィットの獲得',
        Shareuser:'招待ユーザ',
        Financialdetails:'財務の詳細',
        Time:'回',
        Source:'情報源',
        Cancel:'キャンセル',
        Confirm:'認識する'
    },
    tips:{
        Pleaseservice:'カスタマーサービス',
        copysuccess:'コピー成功',
        copyfailed:'コピーは失敗した。',
        Insufficientbalance:'バランスが不十分だ。',
        success:'成功',
        installtrust:'トラストウォレットをインストールしてください',
        minWithdraw:"最低引き出し額20USDT",
        unstakeSuccess:"あなたが質入れしたトークンは1～5営業日以内に解除されます",
        unstakeAudit:"あなたの解除申請は審査中です"
    },
    title:{
        home:"Home",
        pool:"Pool",
        machine:"Machine",
        mime:"Mime"
    }
}
