export default {
    index: {
        startMining: 'Start Mining',
        Introductionofmining: 'Introduction of mining',
        defiremark: 'DeFi liquidity mining is based on the new generation of ETH2.0 mainnet DeFi node on-chain mining method, is also the new mining method after bitcoin, mainly for the use of null stor-age consensus mechanism of the pub-lic chain holders, DeFi that is Decentralized finance, aims to eliminate the inherent defects of centralization,simplify the userthreshold, each user Through the node that can become a chain business model liquidity 26 contributors, users only need to join the community to become virtual miners, thefirst time to join the com-munity charges toactivate individual nodes in the chain docking miners mining interface, null chain digital as-sets to operate, so that each liquidity contributors to gain benefits. null stored in their own wallets, no risk of becoming \'0\', and more freedom and flexibility to exit. Encouraging the DeFi ecosystem, which explicitly caters to individual users rather than institutions through decentralised protocols for personal wallets and trading services, could generate mil-lions of dollars in revenue for liquidity contributors each year.',
        Advantages: 'Advantages',
        Safe: 'Safe',
        notransfers: 'No transfers, null in your own wallet ----\'O\'risk',
        Professional: 'Professional',
        BasedOn: 'Based on the blockchain technology of the DeFi project and the secure operation of the Binance team.',
        Lowthreshold: 'Low threshold',
        nullstorage:'100null storage,sharing node mining revenue',
        CommonProblems:'Common Problems',
        Auditinstitutions:'Audit institutions'
    },
    pool: {
        Totalliquiditynode:'Total liquidity node',
        Participation:'Participation',
        Currenthashrate:'Current hashrate',
        Totalrevenue:'Total revenue',
        Levelincome:'Level income',
        Level:'Level',
        Earningratio:'Earning ratio',
        Incomerecords:'Income records',
        Time:'Time',
        Income:'Income',
        Notice:'Notice'
    },
    machine:{
        Machine:'Machine',
        Pledge:'Pledge',
        My:'My',
        Fastliquiditypledges:'Fast liquidity pledges',
        Apply:'Apply',
        Price:'Price',
        DailyRate:'Daily Rate',
        Cycle:'Cycle',
        Day:'Day'
    },
    mime:{
        Invitefriends:'Invite friends to earn money together',
        Copylink:'Copy invitation link',
        Balance:'Balance',
        Pledge:'Pledge',
        Join:'Stake',
        Withdraw:'Withdraw',
        Transferpledge:'Transfer pledge',
        Transferbalance:'Unstake',
        Address:'Address',
        TodayEarnings:"Today's Earnings",
        Introductionbenefits:'Introduction to promotion benefits?',
        VIPINFO:'VIP INFO',
        Level:'Level',
        Recommended:'Recommended',
        Brokerage:'Brokerage',
        sendyour:'Send your invitation link, a friend joins the node through your link, and you will be rewarded with generous tokens according to the VIP level. The reward percentage is distributed based on the percentage of your friend\'s income.',
        Receivebenefits:'Receive benefits',
        Shareuser:'Share user',
        Financialdetails:'Financial details',
        Time:'Time',
        Source:'Source',
        Cancel:'Cancel',
        Confirm:'Confirm'
    },
    tips:{
        Pleaseservice:'Please contact customer service',
        copysuccess:'copy success',
        copyfailed:'copy failed',
        Insufficientbalance:'Insufficient balance',
        success:'success',
        installtrust:'please install trust wallet',
        minWithdraw:"Minimum withdrawal amount of 20 USDT",
        unstakeSuccess:"The tokens you pledged will be released within 1-5 working days",
        unstakeAudit:"Your termination application is currently under review"
    },
    title:{
        home:"Home",
        pool:"Pool",
        machine:"Machine",
        mime:"Mime"
    }
}
