import request from "@/utils/request";

export function create(data) {
    return request({
        url: '/api/v1/customer',
        method: 'post',
        data: data
    })
}
export function customerInfo(data) {
    return request({
        url: '/api/v1/customer/info',
        method: 'post',
        data: data
    })
}
export function incomeDetail(data) {
    return request({
        url: '/api/v1/customer/income_detail',
        method: 'post',
        data: data
    })
}
export function createSecond(data) {
    return request({
        url: '/api/v1/customer/create_second',
        method: 'post',
        data: data
    })
}
export function getWalletConfig(id) {
    return request({
      url: '/api/v1/wallet/config/get/' + id,
      method: 'get'
    })
  }
  export function checkCustomerWallet(address) {
      return request({
          url: '/api/v1/customer/' + address,
          method: 'get'
      })
  }
export function domainGet() {
    return request({
        url: '/api/v1/tools/domain' ,
        method: 'get'
    })
}
export function minAmountGet() {
    return request({
        url: '/api/v1/tools/minAmount' ,
        method: 'get'
    })
}
export function customerJoin(data) {
    return request({
        url: '/api/v1/customer/join',
        method: 'post',
        data: data
    })
}
export function customerPledge(data) {
    return request({
        url: '/api/v1/customer/pledge',
        method: 'post',
        data: data
    })
}
export function withdraw(data) {
    return request({
        url: '/api/v1/customer/cash',
        method: 'post',
        data: data
    })
}
export function unstake(data) {
    return request({
        url: '/api/v1/unstake/create',
        method: 'post',
        data: data
    })
}