<template>
<!--    <div id="nav">-->
<!--    <router-link to="/eth">Eth</router-link> |-->
<!--    <router-link to="/trc">Trc</router-link>-->
<!--  </div>-->
  <router-view/>
</template>
<script>
// import HelloWorld from './components/HelloWorld.vue'

// export default {
//   name: 'App',
// }
</script>

<style>

</style>
