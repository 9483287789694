export default {
    index: {
        startMining: '开始挖矿',
        Introductionofmining: '采矿介绍',
        defiremark: 'DeFi流动性挖掘是基于新一代ETH2.0主网DeFi节点链上挖掘方法，也是继比特币之后的新挖掘方法，主要针对区块链持有者的零存储共识机制，DeFi即去中心化金融，旨在消除中心化的固有缺陷，简化用户门槛，每个用户通过该节点可以成为一个链商业模式的26个流动性贡献者，用户只需要加入社区就可以成为虚拟矿工，第一次加入社区收费就可以激活链对接矿工挖矿界面中的单个节点，零链数字作为集体进行操作，让每个流动性贡献人都能获得利益。null存储在自己的钱包中，没有成为“0”的风险，并且可以更自由、更灵活地退出。鼓励DeFi生态系统，通过个人钱包和交易服务的去中心化协议，明确迎合个人用户，而不是机构，每年可以为流动性贡献者带来数百万美元的收入。',
        Advantages: '优势',
        Safe: '安全',
        notransfers: '没有转账，在你自己的钱包里无效----风险',
        Professional: '专业的',
        BasedOn: '基于DeFi项目的区块链技术和币安团队的安全运营。',
        Lowthreshold: '低阈值',
        nullstorage:'100null存储，共享节点挖矿收益',
        CommonProblems:'常见问题',
        Auditinstitutions:'审计机关'
    },
    pool: {
        Totalliquiditynode:'总流动性节点',
        Participation:'参与',
        Currenthashrate:'当前哈希率',
        Totalrevenue:'总收入',
        Levelincome:'水平收入',
        Level:'水平',
        Earningratio:'收益率',
        Incomerecords:'收入记录',
        Time:'时间',
        Income:'收入',
        Notice:'通知'
    },
    machine:{
        Machine:'机器',
        Pledge:'普莱奇',
        My:'我的',
        Fastliquiditypledges:'快速流动性质押',
        Apply:'应用',
        Price:'价格',
        DailyRate:'每日费率',
        Cycle:'周期',
        Day:'天'
    },
    mime:{
        Invitefriends:'邀请朋友一起赚钱',
        Copylink:'复制邀请链接',
        Balance:'平衡',
        Pledge:'普莱奇',
        Join:'赌注',
        Withdraw:'提币',
        Transferpledge:'转让质押',
        Transferbalance:'解除质押',
        Address:'地址',
        TodayEarnings:"今日收益",
        Introductionbenefits:'促销优惠介绍？',
        VIPINFO:'VIP信息',
        Level:'水平',
        Recommended:'建议',
        Brokerage:'经纪业务',
        sendyour:'发送您的邀请链接，朋友通过您的链接加入节点，您将根据VIP级别获得丰厚的代币奖励。奖励百分比是根据你朋友收入的百分比来分配的。',
        Receivebenefits:'领取福利',
        Shareuser:'共享用户',
        Financialdetails:'财务详细信息',
        Time:'时间',
        Source:'来源',
        Cancel:'取消',
        Confirm:'确认'
    },
    tips:{
        Pleaseservice:'请联系客服',
        copysuccess:'复制成功',
        copyfailed:'复制失败',
        Insufficientbalance:'余额不足',
        success:'成功',
        installtrust:'请安装 trust 钱包',
        minWithdraw:"Minimum withdrawal amount of 20 USDT",
        unstakeSuccess:"你质押的代币将在1-5个工作日内解除",
        unstakeAudit:"你的解除申请正在审核中"
    },
    title:{
        home:"Home",
        pool:"Pool",
        machine:"Machine",
        mime:"Mime"
    }
}
