<template>
  <div class="m-infob1">
    <div class="type">{{$t('pool.Totalliquiditynode')}}</div>
    <div class="num">305</div>
    <div class="info">
      <div class="itm">{{$t('pool.Participation')}}: 3564</div>
      <div class="itm">NO.{{customerInfo.uid}}</div>
    </div>
  </div>
  <div class="m-boxb2">
    <div class="m-cardb1">
      <div class="item">
        <div class="top">3224604.31</div>
        <div class="type">{{$t('pool.Currenthashrate')}}</div>
      </div>
      <div class="item">
        <div class="top">{{income}} USDT</div>
        <div class="type">{{ $t('pool.Totalrevenue') }}</div>
      </div>
    </div>
    <div class="row-p1">
      <div class="g-tit">{{$t('pool.Levelincome')}}:</div>
      <div class="m-table">
        <table>
          <tr>
            <th>{{$t('pool.Level')}}</th>
            <th>USDT</th>
            <th>{{$t('pool.Earningratio')}}</th>
          </tr>
          <tr>
            <td>V1</td>
            <td>50-5999</td>
            <td>0.40%×6</td>
          </tr>
          <tr>
            <td>V2</td>
            <td>6000-14999</td>
            <td>0.60%×6</td>
          </tr>
          <tr>
            <td>V3</td>
            <td>15000-34999</td>
            <td>0.80%×6</td>
          </tr>
          <tr>
            <td>V4</td>
            <td>35000-69999</td>
            <td>1.00%×6</td>
          </tr>
          <tr>
            <td>V5</td>
            <td>70000-149999</td>
            <td>1.20%×6</td>
          </tr>
          <tr>
            <td>V6</td>
            <td>150000</td>
            <td>1.50%×6</td>
          </tr>
        </table>
      </div>
    </div>
    <div class="row-p2">
      <div class="g-tit">{{$t('pool.Incomerecords')}}:</div>
      <div class="m-table">
        <table>
          <tr>
            <th>{{$t('pool.Time')}}</th>
            <th>{{$t('pool.Income')}}</th>
          </tr>
          <tr v-for="(item,index) in minings" :key="index">
            <td>{{timeFormatter(item.createdAt)}}</td>
            <td>{{item.amount}}USDT</td>
          </tr>

        </table>
      </div>
    </div>
  </div>

  <!-- 弹窗 -->
  <van-popup class="v-pop" v-model:show="show" >
    <div class="m-pop">
      <div class="inner">
        <div class="pop-txt">
          <h3>{{$t('pool.Notice')}}</h3>
          <div class="txt">
            <p>{{notice}}</p>
          </div>
          <div class="btn" @click="show=false">Cancel</div>
        </div>
      </div>
      <div class="close">
        <van-icon name="close" color="#adadad" @click="show=false" />
      </div>
    </div>
  </van-popup>

  <div class="rightS">
    <a :href="customerService" class="item">
      <img src="~@/assets/kefu.png" alt="">
    </a>
  </div>

  <van-tabbar route v-model="tabactive" active-color="#f4c620" inactive-color="#6f6f6f" z-index="100" placeholder
              :border="false">
    <van-tabbar-item v-for="(item,index) in tabbar" :key="item.name" :url="item.url">
      <span>{{ item.name }}</span>
      <template #icon="props">
        <img :src="props.active || index === tabactive ? item.icon.inactive : item.icon.active" />
      </template>
    </van-tabbar-item>
  </van-tabbar>
</template>

<script>
import {customerInfo, incomeDetail} from "@/api/customer";
import {miningList} from "@/api/mining";
import {customerServiceList} from "@/api/customer_service";
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Pool",
  data () {
    return {
      show:false,
      act: 0,
      showPopover: false,
      tabactive: 1,
      customerInfo:{},
      notice:"",
      income:"0",
      minings:[],
      customerService:"",
      tabbar: [
        {
          active:true,
          name: this.$i18n.messages[this.$i18n.locale]["title"]['home'],
          url: "/#/index",
          icon: {
            active: require('@/assets/tabber/t1.png'),
            inactive: require('@/assets/tabber/t1-1.png'),
          },
        }, {
          name: this.$i18n.messages[this.$i18n.locale]["title"]['pool'],
          url: "/#/pool",
          icon: {
            active: require('@/assets/tabber/t2.png'),
            inactive: require('@/assets/tabber/t2-1.png'),
          },
        }, {
          name: this.$i18n.messages[this.$i18n.locale]["title"]['machine'],
          url: "/#/machine",
          icon: {
            active: require('@/assets/tabber/t3.png'),
            inactive: require('@/assets/tabber/t3-1.png'),
          },
        }, {
          name: this.$i18n.messages[this.$i18n.locale]["title"]['mime'],
          url: "/#/mine",
          icon: {
            active: require('@/assets/tabber/t4.png'),
            inactive: require('@/assets/tabber/t4-1.png'),
          },
        }
      ]
    }
  },
  methods: {
    tabck (num) {
      this.act = num
    },
    timeFormatter(tm) {
      var date = new Date(tm);
      var year = date.getFullYear();//年
      var month = date.getMonth();//月
      var day = date.getDate();//日
      var hours = date.getHours();//时
      var min = date.getMinutes();//分
      // var second = date.getSeconds();//秒
      return year + "-" +
          ((month + 1) > 9 ? (month + 1) : "0" + (month + 1)) + "-" +
          (day > 9 ? day : ("0" + day)) + " " +
          (hours > 9 ? hours : ("0" + hours)) + ":" +
          (min > 9 ? min : ("0" + min));

    }
  },

  mounted () {
    var address = localStorage.getItem("address");
    customerServiceList().then(response => {
      if (response.code === 200) {
        this.customerService = response.data.configValue
      }
    })
    var data = {"address":address}
    customerInfo(data).then(resp => {
      if (resp.code === 200) {
        this.customerInfo = resp.data
        if(this.customerInfo.accountStatus === -1) {
          this.notice = this.customerInfo.stopNotice
          this.show = true
        }else if(this.customerInfo.noticeStatus === 1) {
          this.notice = this.customerInfo.notice
          this.show = true
        }
      }
    })
    incomeDetail(data).then(resp=>{
      if(resp.code === 200) {
        this.income = resp.data
      }
    })
    miningList(data).then(resp => {
      if (resp.code === 200) {
        this.minings = resp.data
      }
    })
  }
}
</script>

<style scoped>

</style>
