export default {
    index: {
        startMining: 'Inizia l\'estrazione',
        Introductionofmining: 'Introduzione all\'estrazione mineraria',
        defiremark: 'Il mining di liquidità DeFi si basa sulla nuova generazione del consenso del metodo di mining on-chain del nodo DeFi della rete principale ETH2.0, è anche il nuovo metodo di mining dopo bitcoin, principalmente per l\'uso del meccanismo di archiviazione nullo dei detentori di catene pubbliche, La DeFi, ovvero la finanza decentralizzata, mira a eliminare i difetti intrinseci della centralizzazione, a semplificare la soglia utente, ciascun utente Attraverso il nodo che può diventare un modello di business a catena liquidità 26 contributori, gli utenti devono solo unirsi alla comunità per diventare minatori virtuali, la prima volta per unirsi ai costi della comunità per attivare i singoli nodi nell\'interfaccia di mining dei minatori di attracco della catena, asset digitali della catena nulla per operare, in modo che ciascun contributore di liquidità ottenga vantaggi. nullo memorizzato nei propri portafogli, nessun rischio di diventare \'0\' Incoraggiare l’ecosistema DeFi, che si rivolge esplicitamente ai singoli utenti piuttosto che alle istituzioni attraverso protocolli decentralizzati per portafogli personali e servizi di trading, potrebbe generare milioni di dollari di entrate per i contributori di liquidità ogni anno..',
        Advantages: 'Vantaggi',
        Safe: 'Sicuro',
        notransfers: 'Nessun trasferimento, nullo nel tuo portafoglio ----\'0\'rischio',
        Professional: 'Professionale',
        BasedOn: 'Basato sulla tecnologia blockchain del progetto DeFi e sul funzionamento sicuro del team Binance.',
        Lowthreshold: 'Soglia bassa',
        nullstorage:'100 archivi null, condivisione delle entrate del mining dei nodi',
        CommonProblems:'Problemi comuni',
        Auditinstitutions:'Istituzioni di controllo'
    },
    pool: {
        Totalliquiditynode:'Nodo liquidità totale',
        Participation:'Partecipazione',
        Currenthashrate:'Hash attuale',
        Totalrevenue:'Entrate totali',
        Levelincome:'Reddito di livello',
        Level:'Livello',
        Earningratio:'Rapporto di guadagno',
        Incomerecords:'Registri di reddito',
        Time:'Tempo',
        Income:'Reddito',
        Notice:'Avviso'
    },
    machine:{
        Machine:'Macchina',
        Pledge:'Impegno',
        My:'Mio',
        Fastliquiditypledges:'Impegni di liquidità veloci',
        Apply:'Fare domanda a',
        Price:'Prezzo',
        DailyRate:'Tariffa giornaliera',
        Cycle:'Ciclo',
        Day:'Giorno'
    },
    mime:{
        Invitefriends:'Invita gli amici a guadagnare soldi insieme',
        Copylink:'Copia il link dell\'invito',
        Balance:'Bilancia',
        Pledge:'Impegno',
        Join:'palo',
        Withdraw:'Ritirare',
        Transferpledge:'Impegno a trasferire',
        Transferbalance:'Annullare la partecipazione',
        Address:'Indirizzo',
        TodayEarnings:"Guadagni odierni",
        Introductionbenefits:'Introduzione ai benefici di promozione?',
        VIPINFO:'INFO VIP',
        Level:'Livello',
        Recommended:'Consigliato',
        Brokerage:'Intermediazione',
        sendyour:'Invia il tuo link di invito, un amico si unisce al nodo attraverso il tuo link e tu sarai ricompensato con generosi gettoni in base al livello VIP. La percentuale di ricompensa viene distribuita in base alla percentuale di guadagno del vostro amico.',
        Receivebenefits:'Ricevere benefici',
        Shareuser:'Condividi utente',
        Financialdetails:'Dettagli finanziari',
        Time:'Tempo',
        Source:'Fonte',
        Cancel:'Annulla',
        Confirm:'Confermare'
    },
    tips:{
        Pleaseservice:'Contatta il servizio clienti',
        copysuccess:'copia di successo',
        copyfailed:'copia fallita',
        Insufficientbalance:'Equilibrio insufficiente',
        success:'successo',
        installtrust:'installare il portafoglio fiduciario',
        minWithdraw:"Minimum withdrawal amount of 20 USDT",
        unstakeSuccess:"I token che hai promesso saranno rilasciati entro 1-5 giorni lavorativi",
        unstakeAudit:"La tua domanda di recesso è attualmente in fase di revisione"

    },
    title:{
        home:"Inicio",
        pool:"Piscina",
        machine:"Machine",
        mime:"Mime"
    }
}
