export default {
    index: {
        startMining: 'Start Bergbau',
        Introductionofmining: 'Einführung des Bergbaus',
        defiremark: 'DeFi Liquidität Bergbau basiert auf der neuen Generation von ETH2. 0 mainnet DeFi Knoten on-chain Bergbau-Methode, ist auch die neue Bergbau-Methode nach bitcoin, vor allem für die Verwendung von Null-Speicher-Alter Konsens-Mechanismus der öffentlichen Kette Inhaber, DeFi, dass dezentrale Finanzen ist, zielt darauf ab, die inhärenten Mängel der Zentralisierung zu beseitigen, vereinfachen die BenutzerSchwelle, jeder Benutzer Durch den Knoten, der eine Kette Geschäftsmodell Liquidität 26 Beitragszahler werden kann, müssen die Benutzer nur die Gemeinschaft beitreten, um virtuelle Bergleute zu werden, das erste Mal, um die com-munity Gebühren beitreten, um einzelne Knoten in der Kette Docking-Bergleute Bergbau-Schnittstelle zu aktivieren, Null-Kette digitale As-Sets zu betreiben, so dass jede Liquidität Beitragszahler, um Vorteile zu gewinnen. Null in ihren eigenen Geldbörsen gespeichert, kein Risiko, "0" zu werden, und mehr Freiheit und Flexibilität zu verlassen. Die Förderung des DeFi-Ökosystems, das sich durch dezentrale Protokolle für persönliche Wallets und Handelsdienste ausdrücklich an individuelle Nutzer und nicht an Institutionen wendet, könnte den Liquiditätsgebern jedes Jahr Einnahmen in Millionenhöhe bescheren.',
        Advantages: 'Vorteile',
        Safe: 'Safe',
        notransfers: 'Keine Überweisungen, null in der eigenen Brieftasche ----\'0\'risk',
        Professional: 'Profi',
        BasedOn: 'Basierend auf der Blockchain-Technologie des DeFi-Projekts und dem sicheren Betrieb des Binance-Teams.',
        Lowthreshold: 'Niedrige Schwelle',
        nullstorage:'100null Speicher, gemeinsame Nutzung der Einnahmen aus dem Node-Mining',
        CommonProblems:'Allgemeine Probleme',
        Auditinstitutions:'Rechnungshöfe'
    },
    pool: {
        Totalliquiditynode:'Liquiditätsknoten insgesamt',
        Participation:'Teilnahme .',
        Currenthashrate:'Aktuelle Hashrate',
        Totalrevenue:'Gesamtumsatz',
        Levelincome:'Höhe der Einkünfte',
        Level:'Level',
        Earningratio:'Verdienstquote',
        Incomerecords:'Einkommensunterlagen',
        Time:'Zeit',
        Income:'Einkommen',
        Notice:'Notiz'
    },
    machine:{
        Machine:'Gerät',
        Pledge:'Pfand',
        My:'Meine',
        Fastliquiditypledges:'Zusagen für schnelle Liquidität',
        Apply:'Anwenden',
        Price:'Preis',
        DailyRate:'Tagessatz',
        Cycle:'Kreislauf',
        Day:'Tag .'
    },
    mime:{
        Invitefriends:'Freunde zum gemeinsamen Geldverdienen einladen',
        Copylink:'Einladungslink kopieren',
        Balance:'Bilanz',
        Pledge:'Verpfändung',
        Join:'Einsatz',
        Withdraw:'Zurückziehen',
        Transferpledge:'Pfand übertragen',
        Transferbalance:'Abstecken',
        Address:'Adresse',
        TodayEarnings:"Heutige Ergebnisse",
        Introductionbenefits:'Einführung in die Beförderung?',
        VIPINFO:'VIP-INFO',
        Level:'Level',
        Recommended:'Empfohlen',
        Brokerage:'Brokerage',
        sendyour:'Senden Sie Ihren Einladungslink, ein Freund tritt dem Knotenpunkt über Ihren Link bei, und Sie werden mit großzügigen Token entsprechend dem VIP-Level belohnt. Der Prozentsatz der Belohnung richtet sich nach dem Prozentsatz des Einkommens Ihres Freundes.',
        Receivebenefits:'Vorteile erhalten',
        Shareuser:'Benutzer teilen',
        Financialdetails:'Finanzielle Daten',
        Time:'Zeit',
        Source:'Quelle',
        Cancel:'Abbrechen',
        Confirm:'Bestätigen'
    },
    tips:{
        Pleaseservice:'Bitte kontaktieren Sie den Kundenservice',
        copysuccess:'Kopiererfolg',
        copyfailed:'Kopie gescheitert',
        Insufficientbalance:'Unzureichende Balance',
        success:'Erfolg',
        installtrust:'Bitte installieren Sie Trust Wallet',
        minWithdraw:"Minimum withdrawal amount of 20 USDT",
        unstakeSuccess:"Die von Ihnen zugesagten Token werden innerhalb von 1-5 Werktagen freigegeben",
        unstakeAudit:"Ihr Kündigungsantrag wird derzeit geprüft"

    },
    title:{
        home:"Home",
        pool:"Pool",
        machine:"Maschine",
        mime:"Mime"
    }
}
