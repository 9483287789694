import { createI18n } from "vue-i18n";
import en from "./en";
import zh from "./zh";
import es from "./es";
import gr from "./gr";
import fr from "./fr";
import ar from "./ar";
import sp from "./sp";
import jp from "./jp";
const i18n = createI18n({
    locale: localStorage.getItem("language")?localStorage.getItem("language"):"en", //设置本地语言
    messages: {
        zh: zh,
        en: en,
        es: es,//意大利语
        gr: gr,//德语
        fr: fr,//法语
        ar: ar,//阿拉伯语
        sp: sp,//西班牙语
        jp: jp,
    }
})

export default i18n
