export default {
    index: {
        startMining: 'Début de l\'exploitation minière',
        Introductionofmining: 'Introduction à l\'extraction minière',
        defiremark: 'Le minage de liquidités DeFi est basé sur la nouvelle génération de la méthode de minage sur chaîne du nœud ETH2. 0 mainnet DeFi node on-chain mining method, is also the new mining method after bitcoin, mainly for the use of null stor-age consensus mechanism of the pub-lic chain holders, DeFi that is Decentralized finance, aims to eliminate the inherent defects of centralization,simplify the userthreshold, Chaque utilisateur Par le biais du nœud qui peut devenir un modèle d\'affaires de la chaîne de liquidité 26 contributeurs, les utilisateurs ont seulement besoin de rejoindre la communauté pour devenir des mineurs virtuels, la première fois pour rejoindre la com-munauté charges pour activer les nœuds individuels dans la chaîne docking miners interface minière, chaîne nulle as-sets numériques pour fonctionner, de sorte que chaque liquidité contributeurs à gagner des avantages. Les contributeurs de liquidités peuvent ainsi bénéficier d\'une plus grande liberté et d\'une plus grande souplesse pour sortir de la chaîne, car les liquidités sont stockées dans leurs propres portefeuilles et ne risquent pas de devenir des "0". Encourager l\'écosystème DeFi, qui s\'adresse explicitement aux utilisateurs individuels plutôt qu\'aux institutions par le biais de protocoles décentralisés pour les portefeuilles personnels et les services d\'échange, pourrait générer des millions de dollars de revenus pour les apporteurs de liquidités chaque année.',
        Advantages: 'Avantages',
        Safe: 'Sûre',
        notransfers: 'Pas de transfert, zéro dans votre propre portefeuille ----\'0\'risk',
        Professional: 'Professionnel',
        BasedOn: 'Basé sur la technologie blockchain du projet DeFi et le fonctionnement sécurisé de l\'équipe Binance.',
        Lowthreshold: 'Seuil bas',
        nullstorage:'100null storage, sharing node mining income',
        CommonProblems:'Problèmes courants',
        Auditinstitutions:'Institutions d\'audit'
    },
    pool: {
        Totalliquiditynode:'Nœud de liquidités total',
        Participation:'La participation',
        Currenthashrate:'Taux d\'intérêt actuel',
        Totalrevenue:'Recettes totales',
        Levelincome:'Revenu nivelé',
        Level:'Niveau',
        Earningratio:'Taux d\'imposition',
        Incomerecords:'Relevés de recettes',
        Time:'Temps',
        Income:'Revenu',
        Notice:'Avis'
    },
    machine:{
        Machine:'Machine',
        Pledge:'Engagement',
        My:'Mon',
        Fastliquiditypledges:'Engagements de liquidité rapide',
        Apply:'Appliquer',
        Price:'Prix',
        DailyRate:'Taux journalier',
        Cycle:'Cycle',
        Day:'Jour'
    },
    mime:{
        Invitefriends:'Inviter des amis à gagner de l\'argent ensemble',
        Copylink:'Copier le lien de l\'invitation',
        Balance:'Équilibre',
        Pledge:'Engagement',
        Join:'miser',
        Withdraw:'Retrait',
        Transferpledge:'Transfert de gage',
        Transferbalance:'Débloquer',
        Address:'Adresse',
        TodayEarnings:"Résultats d'aujourd'hui",
        Introductionbenefits:'Introduction aux avantages de la promotion ?',
        VIPINFO:'INFO VIP',
        Level:'Niveau',
        Recommended:'Recommandé',
        Brokerage:'Courtage',
        sendyour:'Envoyez votre lien d\'invitation, un ami rejoint le nœud grâce à votre lien, et vous serez récompensé par de généreux jetons en fonction du niveau VIP. Le pourcentage de la récompense est distribué en fonction du pourcentage des revenus de votre ami.',
        Receivebenefits:'Recevoir des prestations',
        Shareuser:'Partager l\'utilisateur',
        Financialdetails:'Détails financiers',
        Time:'Temps',
        Source:'Source',
        Cancel:'Annuler',
        Confirm:'Confirmer'
    },
    tips:{
        Pleaseservice:'Veuillez contacter le service client',
        copysuccess:'succès de copie',
        copyfailed:'échec de la copie',
        Insufficientbalance:'Balance insuffisante',
        success:'succès',
        installtrust:'Veuillez installer le portefeuille fiduciaire',
        minWithdraw:"Minimum withdrawal amount of 20 USDT",
        unstakeSuccess:"Les jetons que vous avez mis en gage seront libérés dans 1 - 5 jours ouvrables",
        unstakeAudit:"Votre demande de décharge est en cours d'examen"

    },
    title:{
        home:"Accueil",
        pool:"Pool",
        machine:"Machine",
        mime:"Mime"
    }
}
