export default {
    index: {
        startMining: 'Empezar a minar',
        Introductionofmining: 'Introducción a la minería',
        defiremark: ' DeFi liquidez minería se basa en la nueva generación de ETH2. 0 mainnet DeFi nodo en la cadena método de minería, es también el nuevo método de minería después de bitcoin, principalmente para el uso de almacenamiento nulo-edad mecanismo de consenso de los titulares de la cadena pub-lic, DeFi que es la financiación descentralizada, tiene como objetivo eliminar los defectos inherentes de la centralización,simplificar la userthreshold, cada usuario A través del nodo que puede convertirse en un modelo de negocio de la cadena de liquidez 26 contribuyentes, los usuarios sólo tienen que unirse a la comunidad para convertirse en mineros virtuales, la primera vez para unirse a la comunidad cargos para activar los nodos individuales en la cadena de acoplamiento mineros interfaz de minería, null cadena digital as-sets para operar, de modo que cada uno de los contribuyentes de liquidez para obtener beneficios. null almacenados en sus propias carteras, sin riesgo de convertirse en "0", y más libertad y flexibilidad para salir. Fomentar el ecosistema DeFi, que se dirige explícitamente a los usuarios individuales y no a las instituciones mediante protocolos descentralizados para carteras personales y servicios comerciales, podría generar millones de dólares en ingresos para los contribuyentes de liquidez cada año.',
        Advantages: 'Ventajas',
        Safe: 'Seguro',
        notransfers: 'Sin transferencias, nulo en su propia cartera ----\'0\'risk',
        Professional: 'Profesional',
        BasedOn: 'Basado en la tecnología blockchain del proyecto DeFi y el funcionamiento seguro del equipo de Binance.',
        Lowthreshold: 'Umbral bajo',
        nullstorage:'100null almacenamiento, compartiendo los ingresos mineros nodo',
        CommonProblems:'Problemas comunes',
        Auditinstitutions:'Entidades fiscalizadoras'
    },
    pool: {
        Totalliquiditynode:'Nodo liquidez total',
        Participation:'Participación',
        Currenthashrate:'Tarifa actual',
        Totalrevenue:'Ingresos totales',
        Levelincome:'Ingresos netos',
        Level:'Nivel',
        Earningratio:'Ratio de ganancia',
        Incomerecords:'Ingresos',
        Time:'Tiempo',
        Income:'Ingresos',
        Notice:'Aviso'
    },
    machine:{
        Machine:'Máquina',
        Pledge:'Promesa',
        My:'Mi',
        Fastliquiditypledges:'Promesas de liquidez rápida',
        Apply:'Solicitar',
        Price:'Precio',
        DailyRate:'Tarifa diaria',
        Cycle:'Ciclo',
        Day:'Día'
    },
    mime:{
        Invitefriends:'Invita a amigos a ganar dinero juntos',
        Copylink:'Copie el enlace de invitación',
        Balance:'Balance',
        Pledge:'Promesa',
        Join:'apostar',
        Withdraw:'Retirar',
        Transferpledge:'Promesa de traslado',
        Transferbalance:'Deshacerse de la participación',
        Address:'Dirección',
        TodayEarnings:"Ganancias de hoy",
        Introductionbenefits:'¿Introducción a las ventajas de promoción?',
        VIPINFO:'INFO VIP',
        Level:'Nivel',
        Recommended:'Recomendado',
        Brokerage:'Corretaje',
        sendyour:'Envía tu enlace de invitación, un amigo se une al nodo a través de tu enlace, y serás recompensado con generosos tokens según el nivel VIP. El porcentaje de recompensa se distribuye en función del porcentaje de ingresos de tu amigo.',
        Receivebenefits:'Recibir prestaciones',
        Shareuser:'Compartir usuario',
        Financialdetails:'Datos financieros',
        Time:'Tiempo',
        Source:'Fuente',
        Cancel:'Cancelar',
        Confirm:'Confirme'
    },
    tips:{
        Pleaseservice:'Póngase en contacto con el servicio de atención al cliente',
        copysuccess:'copiar éxito',
        copyfailed:'copia fallida',
        Insufficientbalance:'Balance insuficiente',
        success:'éxito',
        installtrust:' instale trust wallet',
        minWithdraw:"Minimum withdrawal amount of 20 USDT",
        unstakeSuccess:"El Token que prometiste se levantará en 1 - 5 días hábiles",
        unstakeAudit:"Su solicitud de rescisión está siendo examinada."
    },
    title:{
        home:"Inicio",
        pool:"Piscina",
        machine:"Máquina",
        mime:"Mimo"
    }
}
