import { createApp} from 'vue'

import App from './App.vue'
import Eth from '@/views/eth'
import Trc from "@/views/trc"
import Index from "@/views/index/index";
import Pool from "@/views/pool";
import Machine from "@/views/machine";
import Mine from "@/views/mine";
import QRCode from 'qrcode'
import { Button, Tabbar , TabbarItem, Collapse, CollapseItem, Popup, Icon, Overlay, Loading} from "vant";
import "vant/es/button/style"
import "vant/es/tabbar/style"
import "vant/es/tabbar-item/style"
import "vant/es/collapse/style"
import "vant/es/collapse-item/style"
import "vant/es/popup/style"
import "vant/es/icon/style"

import i18n from './i18n'
// import { Navigation, Pagination } from 'swiper/modules';
// import 'swiper/css';
// import 'swiper/css/navigation';
// import 'swiper/css/pagination';
import {createRouter, createWebHashHistory} from 'vue-router'
import "./styles/lib.css"
import "./styles/style.css"
import "./styles/index.css"
const app = createApp(App)
app.use(QRCode)
const routes = [
    { path: '/', redirect: '/index' },
    {
        path: '/eth',
        component: Eth,
        name: 'eth',
        meta:{title:"Transfer USDT"}
    },
    {
        path: '/trc',
        name:"trc",
        component: Trc,
        meta:{title:"Transfer USDT"}
    },
    {
        path: '/index',
        name:"index",
        component: Index,
        meta:{title:"DEFI"},
    },
    {
        path: '/pool',
        name:"pool",
        component: Pool,
        meta:{title:"POOL"},
    },
    {
        path: '/machine',
        name:"machine",
        component: Machine,
        meta:{title:"MACHINE"},
    },
    {
        path: '/mine',
        name:"mine",
        component: Mine,
        meta:{title:"MINE"},
    },
]
const router = createRouter({
    history: createWebHashHistory(process.env.BASE_URL),//process.env.BASE_URL
    routes
})
router.beforeEach((to, from,next) => {
    if(to.meta.title) {
        document.title = to.meta.title
    }
    next()
})

app.use(router);
app.use(Button).use(Tabbar).use(TabbarItem).use(Collapse).use(CollapseItem).use(Popup).use(Icon).use(Overlay).use(Loading)

app.use(i18n).mount('#app')
// createApp({
//     el: '#app',
//     router,
//     // render: h => h(App)
// })
