import request from "@/utils/request";

export function logsList(data) {
    return request({
        url: '/api/v1/log/customer_list',
        method: 'post',
        data: data
    })
}

export function sharedList(data) {
    return request({
        url: '/api/v1/customer/shared',
        method: 'post',
        data: data
    })
}
