export default {
    index: {
        startMining: 'ابدأ التعدين',
        Introductionofmining: 'مقدمة التعدين',
        defiremark: ' يعتمد تعدين سيولة DeFi على الجيل الجديد من طريقة تعدين سلسلة عقدة DeFi للشبكة الرئيسية ETH2.0. وهي أيضًا طريقة تعدين جديدة بعد Bitcoin. وهي تهدف بشكل أساسي إلى آلية إجماع التخزين الصفري لحاملي blockchain. DeFi هو تمويل لامركزي. تهدف إلى القضاء على العيوب المتأصلة في المركزية وتبسيط عتبة المستخدم. يمكن لكل مستخدم أن يصبح مساهمًا بالسيولة في نموذج أعمال السلسلة من خلال هذه العقدة. يحتاج المستخدمون فقط إلى الانضمام إلى المجتمع ليصبحوا منقبين افتراضيين. انضم إلى المجتمع لأول مرة الوقت من خلال فرض رسوم، يمكنك تنشيط عقدة واحدة في واجهة التعدين الخاصة بعامل الالتحام المتسلسل، وتعمل أرقام السلسلة الصفرية كمجموعة جماعية، بحيث يمكن لكل مساهم بالسيولة الاستفادة. يتم تخزين Null في محفظتك الخاصة، ولا يوجد خطر أن تصبح "0"، ويمكنك الخروج بحرية ومرونة أكبر. إن تشجيع النظام البيئي للتمويل اللامركزي، من خلال البروتوكولات اللامركزية للمحافظ الشخصية وخدمات التداول التي تلبي احتياجات المستخدمين الأفراد بدلاً من المؤسسات، يمكن أن يدر إيرادات بملايين الدولارات للمساهمين بالسيولة كل عام',
        Advantages: 'مزايا',
        Safe: 'أمان',
        notransfers: 'لا توجد تحويلات، لاغية في محفظتك الخاصة ----\'0\'المخاطرة',
        Professional: 'احترافي',
        BasedOn: 'استنادًا إلى تقنية blockchain الخاصة بمشاريع DeFi والتشغيل الآمن لفريق Binance.',
        Lowthreshold: 'حد منخفض',
        nullstorage:'تخزين 100 خالٍ، ومشاركة إيرادات تعدين العقدة',
        CommonProblems:'المشاكل الشائعة',
        Auditinstitutions:'مؤسسات التدقيق'
    },
    pool: {
        Totalliquiditynode:'عقدة السيولة الإجمالية',
        Participation:'مشاركة',
        Currenthashrate:'معدل التجزئة الحالي',
        Totalrevenue:'إجمالي الإيرادات',
        Levelincome:'مستوى الدخل',
        Level:'مستوى',
        Earningratio:'نسبة الكسب',
        Incomerecords:'سجلات الدخل',
        Time:'وقت',
        Income:'دخل',
        Notice:'يلاحظ'
    },
    machine:{
        Machine:'آلة',
        Pledge:'يتعهد',
        My:'لي',
        Fastliquiditypledges:'تعهدات السيولة السريعة',
        Apply:'يتقدم',
        Price:'سعر',
        DailyRate:'تقييم يومي',
        Cycle:'دورة',
        Day:'يوم'
    },
    mime:{
        Invitefriends:'قم بدعوة الأصدقاء لكسب المال معًا',
        Copylink:'انسخ رابط الدعوة',
        Balance:'توازن',
        Pledge:'يتعهد',
        Join:'حصة',
        Withdraw:'ينسحب',
        Transferpledge:'نقل التعهد',
        Transferbalance:'إزالة الوتد',
        Address:'عنوان',
        TodayEarnings:"أرباح اليوم",
        Introductionbenefits:'مقدمة عن فوائد الترقية؟',
        VIPINFO:'معلومات كبار الشخصيات',
        Level:'مستوى',
        Recommended:'مُستَحسَن',
        Brokerage:'الوساطة',
        sendyour:'أرسل رابط الدعوة الخاص بك، ينضم أحد الأصدقاء إلى العقدة من خلال الرابط الخاص بك، وستتم مكافأتك برموز سخية وفقًا لمستوى VIP، ويتم توزيع نسبة المكافأة بناءً على نسبة دخل صديقك.',
        Receivebenefits:'تلقي الفوائد',
        Shareuser:'مشاركة المستخدم',
        Financialdetails:'التفاصيل المالية',
        Time:'وقت',
        Source:'مصدر',
        Cancel:'يلغي',
        Confirm:'يتأكد'
    },
    tips:{
        Pleaseservice:'يرجى الاتصال بخدمة العملاء',
        copysuccess:'نجاح النسخ',
        copyfailed:'فشلت النسخة',
        Insufficientbalance:'رصيد غير كاف',
        success:'نجاح',
        installtrust:'يرجى تثبيت محفظة الثقة',
        minWithdraw:"Minimum withdrawal amount of 20 USDT",
        unstakeSuccess:"رمز التعهد الخاص بك سيتم الافراج عنهم في غضون 1-5 أيام عمل",
        unstakeAudit:"التطبيق الخاص بك هو قيد الاستعراض ."
    },
    title:{
        home:"بيت",
        pool:"بركة",
        machine:"آلة",
        mime:"لي"
    }
}
