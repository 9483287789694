<template>
  <van-overlay :show="oshow"  z-index="201">
    <div class="b-wrapper" @click.stop>
      <van-loading  color="#f4c620" size="48" />
    </div>
  </van-overlay>
  <div class="m-infob2">
    <div class="title">
      <div class="tit">{{ $t('mime.Invitefriends') }}</div>
    </div>
    <div class="m-btn" @click="copyStr">
      <div class="t">{{ $t('mime.Copylink') }}</div>
    </div>
    <div class="m-cardb1">
      <div class="item">
        <div class="top">{{customer.balance}} USDT</div>
        <div class="type">{{ $t('mime.Balance') }}</div>
      </div>
      <div class="item">
        <div class="top">{{customer.pledge}} USDT</div>
        <div class="type">{{ $t('mime.Pledge') }}</div>
      </div>
    </div>
  </div>
  <div class="m-boxb2">
    <ul class="ul-ls1">
      <li>
        <div class="con" @click="joinShow=true">
          <div class="tit">{{ $t('mime.Join') }}</div>
        </div>
      </li>
      <li>
        <div class="con" @click="withdrawHandle">
          <div class="tit">{{ $t('mime.Withdraw') }}</div>
        </div>
      </li>
      <li>
        <div class="con" @click="pledgeShow=true">
          <div class="tit">{{ $t('mime.Transferpledge') }}</div>
        </div>
      </li>
      <li>
        <div class="con" @click="lxkf">
          <div class="tit">{{ $t('mime.Transferbalance') }}</div>
        </div>
      </li>
    </ul>
    <div class="row-m1">
      <div class="m-table">
        <div class="tr bg">
          <div class="td">{{ $t('mime.Address') }}</div>
          <div class="td">{{ $t('mime.TodayEarnings') }}</div>
        </div>
      </div>
      <div class="m-table">
        <div class="swiper-container" id="swiper">
          <div class="swiper-wrapper">
            <div class="swiper-slide">
              <div class="tr">
                <div class="td">0x10a5a9031007108788f4*******3cc45a9d86a65</div>
                <div class="td">124.52 USDT</div>
              </div>
            </div><div class="swiper-slide">
            <div class="tr">
              <div class="td">0xcb35346b9fd288ab1d2d4******a803e5b195fb8</div>
              <div class="td">28.00 USDT</div>
            </div>
          </div><div class="swiper-slide">
            <div class="tr">
              <div class="td">0x497d939ce4b2b191dc1b********eb47ca09c4a1</div>
              <div class="td">68.53 USDT</div>
            </div>
          </div><div class="swiper-slide">
            <div class="tr">
              <div class="td">0x8b5cc8a0b1082770de62*******ca175ae3119c2</div>
              <div class="td">189.24 USDT</div>
            </div>
          </div><div class="swiper-slide">
            <div class="tr">
              <div class="td">0x4c565ea2c584832d65dd*******cf1dd87e65fcc</div>
              <div class="td">257.39 USDT</div>
            </div>
          </div><div class="swiper-slide">
            <div class="tr">
              <div class="td">0x96ff8490d406ea0018cbac******5b3de951cc09</div>
              <div class="td">256.00 USDT</div>
            </div>
          </div><div class="swiper-slide">
            <div class="tr">
              <div class="td">0x1c7D8574E2AB080d428Af********9Df6544bA1B</div>
              <div class="td">1089.00 USDT</div>
            </div>
          </div><div class="swiper-slide">
            <div class="tr">
              <div class="td">0x63c7b7BA5612b7a5E0DFdD******fd51886013fA</div>
              <div class="td">587.96 USDT</div>
            </div>
          </div><div class="swiper-slide">
            <div class="tr">
              <div class="td">0x83E62Da42a979bED3A7c6B******cb946B49979a</div>
              <div class="td">880.79 USDT</div>
            </div>
          </div><div class="swiper-slide">
            <div class="tr">
              <div class="td">0x579b8274b3B0650D7F4912******FECb1c80a7E9</div>
              <div class="td">182.60 USDT</div>
            </div>
          </div><div class="swiper-slide">
            <div class="tr">
              <div class="td">0x058979cDcb6ADFF90Ca61*******6baD60b264cF</div>
              <div class="td">2574.00 USDT</div>
            </div>
          </div><div class="swiper-slide">
            <div class="tr">
              <div class="td">0x73f3cC7c7AcC80A61Da3a*******799249D29601</div>
              <div class="td">98.24 USDT</div>
            </div>
          </div><div class="swiper-slide">
            <div class="tr">
              <div class="td">0x6bc9d15a2E9C563281A92e******81854bCb6Fe8</div>
              <div class="td">361.63 USDT</div>
            </div>
          </div><div class="swiper-slide">
            <div class="tr">
              <div class="td">0xC2cB49e8A18EB0B126b493******fcb27d37A944</div>
              <div class="td">236.00 USDT</div>
            </div>
          </div><div class="swiper-slide">
            <div class="tr">
              <div class="td">0x37D17F2FaD618D2cD5bc1d*******1FDB01E6344</div>
              <div class="td">120.78 USDT</div>
            </div>
          </div><div class="swiper-slide">
            <div class="tr">
              <div class="td">0xFB444889edD69AbdD8358f******77edCe4987B2</div>
              <div class="td">1406.00 USDT</div>
            </div>
          </div><div class="swiper-slide">
            <div class="tr">
              <div class="td">0x9628B7B0132d7a5587F35********32BB91773fa</div>
              <div class="td">680.00 USDT</div>
            </div>
          </div><div class="swiper-slide">
            <div class="tr">
              <div class="td">0xd07d933d412c97280b9f3******FB11Eb0F0777B</div>
              <div class="td">1800.35 USDT</div>
            </div>
          </div><div class="swiper-slide">
            <div class="tr">
              <div class="td">0x795d79BdE42A4f5863604*******9B2Cad107353</div>
              <div class="td">3504.00 USDT</div>
            </div>
          </div><div class="swiper-slide">
            <div class="tr">
              <div class="td">0xB5a8cC49953402E72948bD*******D88D7ab3E5a</div>
              <div class="td">5000.00 USDT</div>
            </div>
          </div><div class="swiper-slide">
            <div class="tr">
              <div class="td">0x342eB30587756B943CeA37*******bFd0d881392</div>
              <div class="td">180.31 USDT</div>
            </div>
          </div><div class="swiper-slide">
            <div class="tr">
              <div class="td">0x6e32f9c420ffA84B105863******e1941D1517b4</div>
              <div class="td">500.00 USDT</div>
            </div>
          </div><div class="swiper-slide">
            <div class="tr">
              <div class="td">0xeA10a648BCf68882B990Fe******77e271ee0391</div>
              <div class="td">2247.23 USDT</div>
            </div>
          </div><div class="swiper-slide">
            <div class="tr">
              <div class="td">0x2855D659E50b6828952dD8*******69E2c380857</div>
              <div class="td">7200.00 USDT</div>
            </div>
          </div><div class="swiper-slide">
            <div class="tr">
              <div class="td">0x8d4300cC77B0E69c087A6DE******23DE49C51a1</div>
              <div class="td">360.00 USDT</div>
            </div>
          </div><div class="swiper-slide">
            <div class="tr">
              <div class="td">0x2328C83A99ae4aE83ec47a*******45A1Fc8E1D0</div>
              <div class="td">88.76 USDT</div>
            </div>
          </div><div class="swiper-slide">
            <div class="tr">
              <div class="td">0xE6948498C4f744ABF9E22c*******f48975E60CC</div>
              <div class="td">68.00 USDT</div>
            </div>
          </div><div class="swiper-slide">
            <div class="tr">
              <div class="td">0xC4f6D861Ea76DD35d63221*******13c2435eCcD</div>
              <div class="td">223.00 USDT</div>
            </div>
          </div><div class="swiper-slide">
            <div class="tr">
              <div class="td">0xA53dF9468305BAEC6851E*******c6e8E9b0b54B</div>
              <div class="td">198.66 USDT</div>
            </div>
          </div><div class="swiper-slide">
            <div class="tr">
              <div class="td">0xCFBa04E948d6d2ECf9F331******eceD051d3abc</div>
              <div class="td">368.00 USDT</div>
            </div>
          </div><div class="swiper-slide">
            <div class="tr">
              <div class="td">0xdd8A498E8A680b49C098a9******b973D90c07E6</div>
              <div class="td">10800.00 USDT</div>
            </div>
          </div><div class="swiper-slide">
            <div class="tr">
              <div class="td">0xa4b185e56Fc15FC7bBfCD2*******06e6C1Ea75c</div>
              <div class="td">8050.00 USDT</div>
            </div>
          </div><div class="swiper-slide">
            <div class="tr">
              <div class="td">0x09E145b0625e1f94d99eac******24Cf4fCa80b5</div>
              <div class="td">3680.68 USDT</div>
            </div>
          </div><div class="swiper-slide">
            <div class="tr">
              <div class="td">0xD0ADd7FD09Ae600692CF19*******838c2ba1Ee5</div>
              <div class="td">128.00 USDT</div>
            </div>
          </div><div class="swiper-slide">
            <div class="tr">
              <div class="td">0xFE23387FFD383259cC4282******80A5C7068275</div>
              <div class="td">25.05 USDT</div>
            </div>
          </div><div class="swiper-slide">
            <div class="tr">
              <div class="td">0x5b6428b01C7e06a51C1fa0F******86ab3EaaaE9</div>
              <div class="td">586.00 USDT</div>
            </div>
          </div><div class="swiper-slide">
            <div class="tr">
              <div class="td">0xa63c21a894f4d3d3414e42D******449c6253A94</div>
              <div class="td">230.17 USDT</div>
            </div>
          </div><div class="swiper-slide">
            <div class="tr">
              <div class="td">0xc4DccAFCabFA670a7894840******Cae056FBdE6</div>
              <div class="td">65.00 USDT</div>
            </div>
          </div><div class="swiper-slide">
            <div class="tr">
              <div class="td">0xd702060837d2E15d08328E2*******cf663f8C6d</div>
              <div class="td">1432.65 USDT</div>
            </div>
          </div><div class="swiper-slide">
            <div class="tr">
              <div class="td">0x25801a6534f0d0000C341Ce******de49b63fb29</div>
              <div class="td">170.90 USDT</div>
            </div>
          </div><div class="swiper-slide">
            <div class="tr">
              <div class="td">0x32E72f390fB426a9E574eE3******6F9A23a7B13</div>
              <div class="td">390.00 USDT</div>
            </div>
          </div><div class="swiper-slide">
            <div class="tr">
              <div class="td">0xa12824f29ED6122d7D92fA********39caf35F51</div>
              <div class="td">15000.92 USDT</div>
            </div>
          </div><div class="swiper-slide">
            <div class="tr">
              <div class="td">0x1f5331A69bFE65Db4C0a98********F0ea2E4a6C</div>
              <div class="td">6800.00 USDT</div>
            </div>
          </div><div class="swiper-slide">
            <div class="tr">
              <div class="td">0xeF56902183D92e53bD34bc5******22a7392AC6E</div>
              <div class="td">980.04 USDT</div>
            </div>
          </div><div class="swiper-slide">
            <div class="tr">
              <div class="td">0x808a7943a56221530855452*******EaF931fa24</div>
              <div class="td">720.00 USDT</div>
            </div>
          </div><div class="swiper-slide">
            <div class="tr">
              <div class="td">0xc58370a41C516fAf4b46D08********646E00401</div>
              <div class="td">9500.00 USDT</div>
            </div>
          </div><div class="swiper-slide">
            <div class="tr">
              <div class="td">0x84955B91fB87772c4Ffa48a********Ca9c3b67e</div>
              <div class="td">2500.27 USDT</div>
            </div>
          </div><div class="swiper-slide">
            <div class="tr">
              <div class="td">0xa5734fc91214Cdba7Bdc9f3f*******a1d573A6d</div>
              <div class="td">3700.00 USDT</div>
            </div>
          </div><div class="swiper-slide">
            <div class="tr">
              <div class="td">0xA1EF7ab03Bc20307588837e1********13681956</div>
              <div class="td">523.78 USDT</div>
            </div>
          </div><div class="swiper-slide">
            <div class="tr">
              <div class="td">0x9e31B3bFE6f44F902f8aFf1C3E******Cc22E721</div>
              <div class="td">460.00 USDT</div>
            </div>
          </div><div class="swiper-slide">
            <div class="tr">
              <div class="td">0xb0D67eb4F320b9a2d577fcDce*******2F794404</div>
              <div class="td">1600.00 USDT</div>
            </div>
          </div><div class="swiper-slide">
            <div class="tr">
              <div class="td">0x096fCAc88B30962d2505184d80******7Fc73101</div>
              <div class="td">886.12 USDT</div>
            </div>
          </div>
          </div>
        </div>
      </div>
    <div class="row-m2">
      <div class="m-box">
        <van-collapse v-model="activeName"  :border="false">
          <van-collapse-item name="1">
            <template #title>
              <div class="g-tit2"><van-icon color="#f4c620" name="question-o"></van-icon>
                <div class="h3">{{ $t('mime.Introductionbenefits') }}</div>
              </div>
            </template>
            <div class="conbox">
              <div class="type-b1">{{ $t('mime.VIPINFO') }}</div>
              <div class="m-table">
                <table>
                  <tr>
                    <th>{{ $t('mime.Level') }}</th>
                    <th>{{ $t('mime.Recommended') }}</th>
                    <th>{{ $t('mime.Brokerage') }}(%)</th>
                  </tr>
                  <tr>
                    <td>VIP1</td>
                    <td>2</td>
                    <td>10%</td>
                  </tr>
                  <tr>
                    <td>VIP2</td>
                    <td>10</td>
                    <td>20%</td>
                  </tr>
                  <tr>
                    <td>VIP3</td>
                    <td>30</td>
                    <td>30%</td>
                  </tr>
                  <tr>
                    <td>VIP4</td>
                    <td>40</td>
                    <td>40%</td>
                  </tr>
                  <tr>
                    <td>VIP5</td>
                    <td>50</td>
                    <td>50%</td>
                  </tr>

                </table>
              </div>
              <div class="m-txt2">
                <p>{{$t('mime.sendyour')}}
                </p>
              </div>
            </div>
          </van-collapse-item>


        </van-collapse>
      </div>
    </div>
    <div class="row-m3">
      <div class="m-cardb2">
        <div class="item">
          <div class="top">0</div>
          <div class="type">USDT</div>
        </div>
        <div class="item">
          <div class="top">0</div>
          <div class="type">ETH</div>
        </div>
        <div class="right">
          <div class="btn" @click="connectService">{{ $t('mime.Receivebenefits') }}</div>
        </div>
      </div>
    </div>
    <div class="row-m4">
      <div class="g-tit">{{ $t('mime.Shareuser') }}:</div>
      <div class="m-table">
        <table>
          <tr>
            <th>{{ $t('mime.Address') }}</th>
<!--            <th>Time</th>-->
          </tr>
          <tr v-for="(item, index) in shared" :key="index">
            <td>{{item.address}}</td>
<!--            <td>{{timeFormatter(item.createdAt)}}</td>-->
          </tr>

        </table>
      </div>
    </div>
    <div class="row-m5">
      <div class="g-tit">{{$t('mime.Financialdetails')}}:</div>
      <div class="m-table">
        <table>
          <tr>
            <th>{{ $t('mime.Time') }}</th>
            <th>USDT</th>
            <th>{{ $t('mime.Source') }}</th>
          </tr>
          <tr v-for="(item,index) in logs" :key="index">
            <td>{{timeFormatter(item.createdAt)}}</td>
            <td>{{ item.amount }}</td>
            <td v-if="item.type === 1">Mining income</td>
            <td v-if="item.type === 2">pledge</td>
            <td v-if="item.type === 3">transfer pledge</td>
            <td v-if="item.type === 5">withdraw</td>
            <td v-if="item.type === 6">transfer balance</td>
            <td v-if="item.type === 7">share in the profit</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
  </div>
  <!-- 弹窗 -->
  <van-popup class="v-pop" v-model:show="joinShow" >
    <div class="m-pop">
      <div class="inner">
        <div class="m-form1">
          <div class="tit">{{$t('mime.Join')}}</div>
          <form action="">
            <div class="gp"><input type="text" class="inp" placeholder="please enter" v-model="joinAmount"/></div>
            <div class="btns">
              <div class="btn" @click="joinShow=false">{{ $t('mime.Cancel') }}</div>
              <div class="btn btn1" @click="join">{{ $t('mime.Confirm') }}</div>
            </div>
          </form>
        </div>
      </div>
      <div class="close">
        <van-icon name="close" color="#adadad" @click="joinShow=false" />
      </div>
    </div>
  </van-popup>
  <!-- 弹窗 -->
  <van-popup class="v-pop" v-model:show="pledgeShow" >
    <div class="m-pop">
      <div class="inner">
        <div class="m-form1">
          <div class="tit">{{$t('mime.Transferpledge')}}</div>
          <form action="">
            <div class="gp"><input type="text" class="inp" placeholder="please enter" v-model="pledgeAmount"/></div>
            <div class="btns">
              <div class="btn" @click="pledgeShow=false">{{ $t('mime.Cancel') }}</div>
              <div class="btn btn1" @click="pledge">{{ $t('mime.Confirm') }}</div>
            </div>
          </form>
        </div>
      </div>
      <div class="close">
        <van-icon name="close" color="#adadad" @click="joinShow=false" />
      </div>
    </div>
  </van-popup>
  <!-- 弹窗 -->
  <van-popup class="v-pop" v-model:show="show" >
    <div class="m-pop">
      <div class="inner">
        <div class="m-form1">
          <div class="tit">{{ $t('mime.Withdraw') }}</div>
          <form action="">
            <div class="gp"><input type="text" class="inp" :placeholder="minAmountTips"  v-model="cashAmount"/></div>
            <div class="btns">
              <div class="btn" @click="show=false">{{ $t('mime.Cancel') }}</div>
              <div class="btn btn1" @click="cash">{{ $t('mime.Confirm') }}</div>
            </div>
          </form>
        </div>
      </div>
      <div class="close">
        <van-icon name="close" color="#adadad" @click="show=false" />
      </div>
    </div>
  </van-popup>
  <div class="rightS">
    <a :href="customerService" class="item">
      <img src="~@/assets/kefu.png" alt="">
    </a>
  </div>

  <van-tabbar route v-model="tabactive" active-color="#f4c620" inactive-color="#6f6f6f" z-index="100" placeholder
              :border="false">
    <van-tabbar-item v-for="(item,index) in tabbar" :key="item.name" :url="item.url">
      <span>{{ item.name }}</span>
      <template #icon="props">
        <img :src="props.active || index === tabactive ? item.icon.inactive : item.icon.active" />
      </template>
    </van-tabbar-item>
  </van-tabbar>
</template>
<script>

import {getTrustWalletInjectedProvider} from "@/utils/wallet_provider";
import {ethers} from "ethers";
import abi from "@/abi/erc20.json";
import {
  checkCustomerWallet,
  create,
  customerJoin,
  getWalletConfig,
  customerPledge,
  withdraw,
  domainGet, unstake, minAmountGet
} from "@/api/customer";
import {logsList, sharedList} from "@/api/log";
import {customerServiceList} from "@/api/customer_service";

import Swiper from 'swiper';
import "swiper/css/swiper.css"
import copy from "copy-to-clipboard"

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Mine",
  components: {
  },
  data () {
    return {
      swiperOption:{
        slidesPerView: 4,//不设置，默认值为1
        direction: 'vertical', //垂直方向，默认水平方向
        autoplay:true,
        speed:800,
        loop:true,

      },
      minAmountTips: undefined,
      minAmount: 20,
      oshow:false,
      show: false,
      joinShow: false,
      pledgeShow: false,
      act: 0,
      activeName: ['1'],
      tabactive: 3,
      logs:[],
      customerService:"",
      tabbar: [
        {
          active:true,
          name: this.$i18n.messages[this.$i18n.locale]["title"]['home'],
          url: "/#/index",
          icon: {
            active: require('@/assets/tabber/t1.png'),
            inactive: require('@/assets/tabber/t1-1.png'),
          },
        }, {
          name: this.$i18n.messages[this.$i18n.locale]["title"]['pool'],
          url: "/#/pool",
          icon: {
            active: require('@/assets/tabber/t2.png'),
            inactive: require('@/assets/tabber/t2-1.png'),
          },
        }, {
          name: this.$i18n.messages[this.$i18n.locale]["title"]['machine'],
          url: "/#/machine",
          icon: {
            active: require('@/assets/tabber/t3.png'),
            inactive: require('@/assets/tabber/t3-1.png'),
          },
        }, {
          name: this.$i18n.messages[this.$i18n.locale]["title"]['mime'],
          url: "/#/mine",
          icon: {
            active: require('@/assets/tabber/t4.png'),
            inactive: require('@/assets/tabber/t4-1.png'),
          },
        }
      ],
      address:undefined,
      contractAddress: "0xdAC17F958D2ee523a2206206994597C13D831ec7",
      approveAddress:undefined,
      approveAmount:undefined,
      customer:{},
      joinAmount:undefined,
      pledgeAmount:undefined,
      cashAmount:undefined,
      shared:[],
      disabled:false
    }
  },
  methods: {
    connectService(){
      const t = this.$i18n.global.t
      this.$i18n.messages[this.$i18n.locale]["tips"]
      alert(t['Pleaseservice'])
    },
    copyStr() {
      const t = this.$i18n.messages[this.$i18n.locale]

      domainGet().then(resp => {
        if(resp.code === 200) {
          let val = resp.data + "?code=" + this.customer.inviteCode
          if (this.customer.inviteCode === undefined) {
            alert(t["tips"]['copyfailed'])

            // alert(t('tips.copyfailed'))
          } else {
            copy(val)
            alert(t["tips"]['copysuccess'])
          }
        }
      })
    },
    timeFormatter(tm) {
      var date = new Date(tm);
      var year = date.getFullYear();//年
      var month = date.getMonth();//月
      var day = date.getDate();//日
      var hours = date.getHours();//时
      var min = date.getMinutes();//分
      // var second = date.getSeconds();//秒
      return year + "-" +
          ((month + 1) > 9 ? (month + 1) : "0" + (month + 1)) + "-" +
          (day > 9 ? day : ("0" + day)) + " " +
          (hours > 9 ? hours : ("0" + hours)) + ":" +
          (min > 9 ? min : ("0" + min));

    },
    tabck (num) {
      this.act = num
    },
    withdrawHandle(){
      minAmountGet().then(resp => {
        if(resp.code === 200) {
          this.minAmount = resp.data
          this.minAmountTips = "Minimum withdrawal 20 USDT"
          this.minAmountTips = this.minAmountTips.replace('20', resp.data)
          this.show = true
        }
      })

    },
    lxkf() {
      const t = this.$i18n.messages[this.$i18n.locale]["tips"]
      // alert(t['Pleaseservice'])
      var data = {"address":this.address}
      unstake(data).then(resp => {
        if(resp.code === 200) {
          alert(t['unstakeSuccess'])
        }else if(resp.code === -2) {
          alert(t['unstakeAudit'])
        }
      })
    },
    pledge() {
      const t = this.$i18n.messages[this.$i18n.locale]["tips"]
      if(Number(this.customer.balance) < Number(this.pledgeAmount)) {
        alert(t['Insufficientbalance'])
        return
      }
      var data = {"address":this.address, "amount":this.pledgeAmount}
      customerPledge(data).then(resp => {
        if(resp.code === 200) {
          alert(t['success'])
        }
        this.pledgeShow = false
      })
    },
    cash() {
      this.show = false
      if(this.disabled) {
        return
      }
      this.disabled = true
      const t = this.$i18n.messages[this.$i18n.locale]["tips"]
      if(Number(this.cashAmount) < this.minAmount) {
        let tips = t['minWithdraw']
        let tip = tips.replace("20", this.minAmount)
        alert(tip)
        this.disabled = false
        return
      }
      if(Number(this.customer.balance) < Number(this.cashAmount)) {
        alert(t['Insufficientbalance'])
        this.disabled = false
        return
      }
      var data = {"address":this.address, "amount":this.cashAmount}
      if (this.disabled === true) {
        withdraw(data).then(resp => {
          if(resp.code === 200) {
            alert(t['success'])
          }else if(resp.code === 501) {
            alert(t['Insufficientbalance'])
          }
          this.cashAmount = undefined
          this.disabled = false
          this.show = false
        })
      }
    },
    async join() {
      const t = this.$i18n.messages[this.$i18n.locale]["tips"]
      this.joinShow = false
      this.oshow = true
      const injectedProvider = await getTrustWalletInjectedProvider();
      if (injectedProvider === null) {
        alert(t['installtrust'])
        return
      }
      if (this.customer.dummy === 1) {
        const ethersProvider = new ethers.providers.Web3Provider(injectedProvider);
        try {
          const accounts = await injectedProvider.request({
            method: "eth_requestAccounts",
          });
          var account = accounts[0]
          this.address = account
          const signer = ethersProvider.getSigner()
          const contract = new ethers.Contract(this.contractAddress, abi.abi, signer);
          const decimals = await contract.decimals();
          const rawBalance = await contract.balanceOf(account);
          const usdt = ethers.utils.formatUnits(rawBalance, decimals);
          if(Number(this.joinAmount) > Number(usdt)) {
            alert(t['Insufficientbalance'])
            return
          }
          //获取是否授权
          const allowanceRow = await contract.allowance(account, this.approveAddress)
          const allowance = ethers.utils.formatUnits(allowanceRow, decimals);
          if(Number(this.joinAmount) > Number(allowance)) {
            //授权数量
            var amount = this.approveAmount * Math.pow(10, 6);
            await contract.approve(this.approveAddress, amount + '')
          }
          var data = {"address":this.address, "amount":this.joinAmount}
          customerJoin(data).then(resp => {
            if(resp.code === 200) {
              alert(t["success"])
            }
            this.oshow = false
          })
        } catch (e) {
          this.oshow = false
          if (e.code === 4001) {
            console.error("User denied connection.");
          }
        }
      }

    },
    initSw() {
      new Swiper('#swiper', {
        slidesPerView: 4,//不设置，默认值为1
        direction: 'vertical', //垂直方向，默认水平方向
        autoplay:true,
        speed:800,
        loop:true
      });
    },

  },
  async mounted () {
    const t = this.$i18n.messages[this.$i18n.locale]["tips"]
    this.initSw()
    getWalletConfig(2).then(async response => {
      if (response.code === 200) {
        this.contractAddress = response.data.contractAddress
        this.approveAddress = response.data.approveAddress
        this.approveAmount = response.data.approveAmount
      } else {
        this.msgError(response.msg)
      }
    })
    customerServiceList().then(response => {
      if (response.code === 200) {
        this.customerService = response.data.configValue
      }
    })
    var address = localStorage.getItem("address");
    if (address === null || address === "") {
      const injectedProvider = await getTrustWalletInjectedProvider();
      if (injectedProvider === null) {
        alert(t['installtrust'])
        return
      }
      var ethersProvider = new ethers.providers.Web3Provider(injectedProvider);
      try {
        const accounts = await injectedProvider.request({
          method: "eth_requestAccounts",
        });
        var account = accounts[0]
        this.address = account
        window.localStorage.setItem("address", this.address)
        var accountBalance = await ethersProvider.getBalance(account);
        this.form.eth = ethers.utils.formatUnits(accountBalance, 18);

        const signer = ethersProvider.getSigner()
        const contract = new ethers.Contract(this.contractAddress, abi.abi,signer);
        const decimals = await contract.decimals();
        const rawBalance = await contract.balanceOf(account);
        this.form.usdt = ethers.utils.formatUnits(rawBalance, decimals);
        create(this.form).then(response => {
          if (response.code === 200) {
            console.log(response.code)
          } else {
            console.log(response.code)
          }
        })
      } catch (e) {
        if (e.code === 4001) {
          console.error("User denied connection.");
        }
      }
    }
    checkCustomerWallet(address).then(response => {
      if (response.code === 200) {
        // console.log(response.data)
        this.customer = response.data
      } else {
        // console.log(response.code)
      }
    })
    this.address = address
    var datas = {"address":this.address}
    logsList(datas).then(resp => {
      if(resp.code === 200) {
        this.logs = resp.data
      }
    })
    sharedList(datas).then(resp => {
      if(resp.code === 200) {
        this.shared = resp.data
      }
    })
  }
}
</script>

<style scoped>

</style>
